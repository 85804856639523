import React from "react";
// Customizable Area Start
import {
  Box, 
  Button, 
  ClickAwayListener, 
  Container, 
  Dialog, 
  DialogActions, 
  FormControl, 
  Grid, 
  IconButton, 
  InputBase, 
  InputLabel,
  Popper, 
  Tab, 
  Tabs, 
  Typography, 
  styled, 
  withStyles, 
  TextField, 
  Checkbox, 
  Collapse, 
  FormControlLabel, 
  Chip
} from "@material-ui/core";
import ProjectText from "../../../components/src/ProjectText";
import {
  AddcloseImg, Matches, image_success, RemoveIcon,
  arraw, redQue, resultNotFound,resultNotFound1, upload, pdf, image_dropdown, terms, DateTimeIcon, reject, blueQue
} from "./assets";
import SellerBookingsController from "./SellerBookingsController";
import CustomizedSteppers from "../../../components/src/StepFile.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import SellerVeticalFile from "../../../components/src/SellerVerticalFile.web";
import { Close } from "@material-ui/icons";
import Navbar from "../../../components/src/NavBar.web";
import AddListing from "../../../components/src/AddListing";
import CancelIcon from '@material-ui/icons/Cancel';
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import { SectionWrapperLeft, webStyle, CustomBox } from "./SellerListing.web"
import Spinner from "../../../components/src/ProjectLoader";

// Customizable Area End

export const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    height: "100%", 
    width: "100%",
    maxHeight: "720px", 
    borderRadius: "24px",
    maxWidth: "800px"
  },
  "& .MuiDialog-paper.MuiDialog-paperScrollPaper": { 
    width: "100%",
    height: "100%", 
    maxWidth: "800px",
    maxHeight: "720px", 
    borderRadius: "24px",
    overflowY: "auto", // Enable vertical scrolling
  },
  "& .MuiDialog-paper.MuiDialog-paperScrollPaper::-webkit-scrollbar": {
    width: "8px",
  },
  "& .MuiDialog-paper.MuiDialog-paperScrollPaper::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "4px",
  },
  "& .MuiDialog-paper.MuiDialog-paperScrollPaper::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#888",
  },
  "& .titleTypography": {
    textAlign: "left", color: "#1E1E1E",
    fontFamily: "Asap",fontStyle: "normal",
    fontWeight: 1000, lineHeight: "normal",
    fontSize: "14px",textTransform: "uppercase",
    whiteSpace:"nowrap",
  },
  "& .smallText": {
    color: "#363636",fontFamily: "Asap",
    fontStyle: "normal",fontWeight: 500,
    fontSize: "14px", lineHeight: "25px"
  },
  "& .CancleBtn": {
    display: "flex",justifyContent: "center",
    alignItems: "center",lineHeight: "16px",
    background: "#D22",color: "var(--Basic-White, #FFF)", 
    margin: "auto", borderRadius: "20px",
    padding: "6px 10px",fontStyle: "normal", 
    fontFamily: "Asap",width: "175px", 
    fontSize: "14px",height: "32px", 
    textTransform: "uppercase",fontWeight: 700,
  },
  "& .CancleBtns": {
    display: "flex",justifyContent: "center",
    alignItems: "center",lineHeight: "16px",
    color: "var(--Basic-White, #FFF)", 
    margin: "auto",borderRadius: "20px",
    padding: "6px 10px",fontStyle: "normal", 
    fontFamily: "Asap",width: "300px", 
    fontSize: "12px",height: "32px", 
    background: "gray",textTransform: "uppercase",
    fontWeight: 700,
  },
  "& .dialogTitle": {
    color: "#1E1E1E",textAlign: "center" as const,
    fontFamily: "Asap",fontSize: "28px",
    fontWeight: 1000,lineHeight: "normal",
    fontStyle: "normal"
  },
  "& .topBox": {
    background: "#DCECFF",padding: "0",
    margin: "0", display: "flex",
    width: "100%",flexDirection: "column" as const,
    position: "relative"
  },
  "& .icon": {
    color: "#a31c1c",width: "100%",
    display: "flex", alignItems: "center",
    justifyContent: "center",fontSize: "4em"
  },
  "& .headerDialod": {
    marginTop: "35px", marginBottom:"20px",
    position: "relative"
  },
  "& .stepperBox": {
    padding: "8%",
     paddingTop: "2%",
    "@media (max-width: 768px)": {
      padding:"0px"
    },
  },
  "& .closeIconHeader": {
    position: "absolute", zIndex: 1,
    background: "black", right: "10px",
    top: "7px",  border: "1px solid black",
    borderRadius: "50%",padding: "0",
    margin: "0", color: "white",
    height: "22px",display: "flex",
    justifyContent: "center",width: "22px",
    alignContent: "center" as const,
    "& button": { color: "#fff" },
  },
  "& .stepperBoxcancel": {
    textAlign: "center", 
    margin: "5%"
  },
  "& .midCardSection": {
    display: "flex", 
    justifyContent: "space-evenly",
    flexWrap:"wrap",
    width: "100%",
    gap:"10px",
     margin: "20px 0",
     padding:"0 20px",
    "@media (max-width: 768px)": {
      display:"grid", 
      gridTemplateColumns:"repeat(2,1fr)",
      justifyContent:"center", 
      gap:"16px",
      paddingLeft:"16px"
 
     },
  },
  "& .midSmallcard" : {
    width:'150px' 
  },
  "& .midSmallcards" : {
    width:'129px' 
  },
  "& .midSmallcards1" : {
    paddingLeft: '15px',
    width: "100%",
    paddingTop: '10px',
  },
  "& .nominationCanceltext": {
    color: "black", fontFamily: "Asap",
    fontStyle: "normal",fontWeight: 500,
    fontSize: "14px"
  },
  "& .nominationCancel": {
    color: "black",  fontFamily: "Asap",
    fontStyle: "normal",fontWeight: 700,
    fontSize: "24px"
  },
  "& .nominationCancelbuttons": {
    color: " #FFF",
    width: "240px",
    height: " 32px",
    display: " flex",
    padding: "25px 70px",
    fontSize: "14px",
    background: "#D22",
    alignItems: "center",
    fontFamily: "Asap",
    fontWeight: 700,
    lineHeight: "16px",
    borderRadius: "32px",
    margin: "1%"
  },
  "& .nominationCancelbuttonsOutlined": {
    margin:"1%", 
    borderColor: "#D22", 
    alignItems: "center",
    fontFamily: "Asap", 
    borderRadius: "32px",
    fontWeight: 700,
    color: "#D22",
    width: "236px",
    lineHeight: "16px",
    height: "28px", 
    display: " flex",
    padding: "25px 70px", 
    fontSize: "14px",
    borderWidth: '1px'
  },
  "& .nominateCancelgrid": {
    display: "inline-flex !important",
    margin: "3% !important"
  },
});
const StyledBox = withStyles((theme) => ({
  root: {
    position: "relative", 
    padding: "1.25rem 6.875rem", 
    backgroundColor: theme.palette.common.white, 
    border: ".0625rem solid #ced4da",
    fontSize: 16, 
    borderRadius: 8, 
    transition: theme.transitions.create(["border-color", "box-shadow"]), 
    cursor: "pointer",
    // Use the system ont instead of the default Roboto font.
    "&:focus": { borderColor: "#000" },
  }
}))(Box);

const StyledChip = withStyles((theme) => ({
  root: {
    fontFamily: "Asap, sans-serif", 
    background: "blue", 
    color: "white", 
    fontSize: ".875rem", 
    flexDirection: "row-reverse",
    "& .MuiChip-deleteIcon": { 
      color: "rgba(255, 255, 255, 1)" }, "& .MuiChip-label": { 
        paddingRight: "0rem" },
    "& .MuiChip-icon": { 
      color: "rgba(255, 255, 255, 1)", 
      margin: "0rem" }
  },
}))(Chip);

export default class SellerBookings extends SellerBookingsController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  sucessmessage(Message: string, Text: string) {
    return (
      <Grid container>
        <Grid item className="nominationClose" lg={12} md={12} sm={12} xs={12}> <img className="nominationCloseimg" onClick={() => { this.handleClose(Message); }} src={AddcloseImg} alt="" />
        </Grid>
        <Grid className="nominationMsggrid" item lg={12} md={12} sm={12} xs={12}> <img className="nominationErrorimg" src={image_success} alt="" />
          <Typography className="nominationHeading">{Message}</Typography><Typography className="nominationLinktext">{Text}</Typography>
        </Grid>
      </Grid>
    );
  }

  TextWithEllipsis = ({ text, maxLength }: any) => {
    if (!text || text.length <= maxLength) {
      return <span>{text}</span>;
    }
    const truncatedText = text.slice(0, maxLength) + '...';
    return (
      <span title={text}>
        {truncatedText}
      </span>
    );
  };
  renderDialog = () => {
    const colorMap: any = {
      pending: "#EE8F00",
      accepted: "#0f0",
      rejected: "#f00"
    };
    const bookingStatus = this.state.selectedBooking?.attributes?.booking_status?.toLowerCase();
    const color: any = colorMap[bookingStatus];
    const cancelButtonTitle = this.state.selectedBooking?.attributes?.booking_confirmation_status == "Booking Cancelled" ? "Cancelled By Seller" : "Booking Cancelled";
    return (
      <StyledDialog open={this.state.trackingModal}>
        <Box className="topBox">
            <Box className="closeIconHeader"> <IconButton size="small" data-test-id="closeTrackModel" onClick={() => { this.handleClose("trackingModal"); }}><Close fontSize="small"/></IconButton> </Box>
          <Box className="headerDialod">
            <Typography variant="h5" className="dialogTitle"> Booking Details </Typography>
          </Box>
          <Grid className="midCardSection">
            <Grid item lg={2} md={2} sm={6} xs={12} className="midSmallcard">
              <Typography variant="body2" className="titleTypography">slotxpert #</Typography> <Typography className="smallText">{this.state.selectedBooking?.attributes?.booking_uid}</Typography>
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={12} className="midSmallcard">
              <Typography variant="body2" className="titleTypography">vessel/voyage</Typography> 
              <Typography className="smallText">
                {this.TextWithEllipsis({ text: this.state.selectedBooking?.attributes?.vessel_voyage && this.state.selectedBooking?.attributes?.in_voyage && `${this.state.selectedBooking?.attributes?.vessel_voyage}  / In- ${this.state.selectedBooking?.attributes?.in_voyage} Out- ${this.state.selectedBooking?.attributes?.out_voyage}`, maxLength: 30 })}
              </Typography>
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={12} className="midSmallcard">
              <Typography variant="body2" className="titleTypography">pol</Typography> <Typography className="smallText">{this.state.selectedBooking?.attributes?.pol}</Typography>
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={12} className="midSmallcard">
              <Typography variant="body2" className="titleTypography">pod</Typography> <Typography className="smallText">{this.state.selectedBooking?.attributes?.pod}</Typography>
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={12} className="midSmallcard">
              <Typography variant="body2" className="titleTypography"> SELLER BOOKING #</Typography> <Typography className="Pending">
                <text style={{ fontFamily: 'Asap',color: this.state.selectedBooking?.attributes?.booking_status === 'rejected' ? 'red' : '#EE8F00',fontWeight:700, fontSize: '14px'}}>{this.state.selectedBooking?.attributes?.booking_reference_number || this.state.selectedBooking?.attributes?.booking_status?.toUpperCase()}</text>
              </Typography>
            </Grid>
            
          {/* </Grid>
          <Box className="midCardSection"> */}
            <Box className="midSmallcards">
              <Typography variant="body2" className="titleTypography">CONTAINERS </Typography>
              <Typography className="smallText">
              {this.state.selectedBooking?.attributes?.offer_quantity ?
                  <>
                {this.state.selectedBooking.attributes?.offer_quantity} X
                {this.state.selectedBooking?.attributes?.container_size} {" "}
                {this.state.selectedBooking?.attributes?.container_type} <br />
                </> : null}
                {this.state.selectedBooking?.attributes?.offer_sec_quantity ?
                  <>
                    {this.state.selectedBooking?.attributes?.offer_sec_quantity} X
                    {this.state.selectedBooking?.attributes?.sec_container_sizes} {" "}
                    {this.state.selectedBooking?.attributes?.container_type}
                  </> : null}
              </Typography>
            </Box>
            <Box className="midSmallcards">
              <Typography variant="body2" className="titleTypography">RATES</Typography>
              <Typography className="smallText">
                {
                  this.state.selectedBooking?.attributes?.offer_quantity && 
                  <>
                  <span style={{ fontWeight: 700 }}>{`$${parseInt(this.state.selectedBooking?.attributes?.seller_offer_rate).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})}/`}
                  </span>
                  {this.state.selectedBooking?.attributes?.container_size} <br />
                  </>
                }
                {this.state.selectedBooking?.attributes?.offer_sec_quantity ?
                  <>
                    <span style={{ fontWeight: 700 }}>
                    {this.state.selectedBooking?.attributes?.seller_sec_offer_rate && 
                    `$${parseInt(
                        this.state.selectedBooking?.attributes?.seller_sec_offer_rate).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}/`}
                      
                    </span>
                    {this.state.selectedBooking?.attributes?.sec_container_sizes}
                  </> : null}
              </Typography>
            </Box>
            <Box className="midSmallcards">
              <Typography variant="body2" className="titleTypography">ETB</Typography>
              <Typography className="smallText">
                <Typography className="smallText">
                  {moment(this.state.selectedBooking?.attributes?.etb).format(
                    "D MMM yyyy"
                  )}{" "}
                  <br />
                  {moment(this.state.selectedBooking?.attributes?.etb).format(
                    "hh:mm A"
                  )}
                </Typography>
              </Typography>
            </Box>
            <Box className="midSmallcards">
              <Typography variant="body2" className="titleTypography">ETA</Typography>
              <Typography className="smallText">
                {moment(this.state.selectedBooking?.attributes?.eta).format(
                  "D MMM yyyy"
                )}{" "}
                <br />
                {moment(this.state.selectedBooking?.attributes?.eta).format(
                  "hh:mm A"
                )}
              </Typography>
            </Box>
            <Box className="midSmallcards" style={{ visibility: "hidden" }}>
              <Typography variant="body2" className="titleTypography">ETA</Typography>
              <Typography className="smallText"> 2 SEP 2023 <br /> 12:00 PM </Typography>
            </Box>
            {
              this.state.selectedBooking?.attributes?.seller_booking_cancel_reason && 
              <Grid item lg={12} md={12} sm={12} xs={12} className="midSmallcards1">
                <Typography variant="body2" className="titleTypography">Reason for Cancellation</Typography> 
                <Typography className="smallText">
                  {this.state.selectedBooking?.attributes?.seller_booking_cancel_reason}
                </Typography>
              </Grid>
            }
        </Grid>
          </Box>
        {/* Cancel Booking Modal Start */}
        {this.state.cancelBooking ? (
          <Box className="stepperBoxcancel">
            <img className="nominationErrorimg" src={redQue} alt="" />
            <Typography className="nominationCancel">Cancel Booking</Typography>
            <Typography className="nominationCanceltext">Are you sure you want to cancel your booking #{this.state.selectedBooking?.attributes?.booking_uid}.</Typography>
            <Box className="nominateCancelgrid">  <Button onClick={this.Cancelbooking} variant="contained" className="nominationCancelbuttons">CANCEL</Button>
              <Button onClick={this.handleNocancel} variant="outlined" className="nominationCancelbuttonsOutlined">No</Button>
            </Box>
          </Box>
        ) : (
          <Box className="stepperBox">
            <SellerVeticalFile data-test-id="SellerVerticalTest" newState={this.state.activeState} handleNominationModal={this.handleNominationmodal} handleApprovalModal = {this.handleApprovalModal}
              selectValue={this.state.accordianData} booking={this.state.selectedBooking} />
            <DialogActions> 
            {this.state.selectedBooking?.attributes?.booking_status == "rejected" ?
                          <Button
                            variant="contained"
                            disabled={true}
                            className="CancleBtns"                         
                             >
                            {cancelButtonTitle}
                          </Button>
               : <Button onClick={this.handleCancelbooking} variant="contained" className="CancleBtn">CANCEL BOOKING</Button>}
            </DialogActions>
          </Box>
        )}
      </StyledDialog>
    );
  };
  renderFinalBL = () => {
    return <>
      {this.state.finalBL && (
        <Grid>
          <StyledDialogModal open={this.state.finalBL}>
            <Grid container>
              <Grid item className="nominationClose" lg={12} md={12} sm={12} xs={12}>
                <img src={AddcloseImg} data-test-id="finalBL" alt="" className="nominationCloseimg" onClick={() => { this.handleClose("finalBL"); }} />
              </Grid>
              <Grid className="nominationMsggrid" item lg={12} md={12} sm={12} xs={12}>
                <Typography className="nominationHeading">Send Final B/L</Typography>
                <Box style={{ marginTop: "2%" }}>
                  <Grid item xs={6} >
                    <Typography className="verificationText" style={{ marginLeft: '25%' }}> UPLOAD FILE </Typography>
                  </Grid>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    {this.state.finalBLDoc?.length === 0 ? (
                      <label htmlFor="pdf" data-test-id="FirsTFormDocumentDrag"
                        onDragEnter={(event) => this.handleDrag(event)}
                        onDrop={(event) => this.handleDropFile(event)}
                        onDragLeave={(event) => this.handleDrag(event)}
                        onDragOver={(event) => this.handleDrag(event)}>
                        <Button variant="text" fullWidth id="PdfFile" component="span" size="large" className="UploadFileBtn"
                          startIcon={<img src={upload} data-test-id="DeleteBusinessPdf" alt="upload" className="SmallIcon" />}>
                          <ProjectText fontweight="500" variant="subtitle1" TextSize=".875rem" textcolor="#797979" noWrap>Browse or drag & drop file here (Only PDF)</ProjectText>
                        </Button>
                      </label>
                    ) : (
                      <Grid item xs={6} spacing={2}>
                        <Button className={`UploadFileBtn`} variant="text" fullWidth id="PdfFile" component="span" size="large" style={{ display: "flex" }}>
                          <Grid xs={8}>
                            <Box className="textandPdfIcon">
                              <img src={pdf} alt="upload" className="SmallIcon" style={{ marginLeft: '8px' }} />
                              <ProjectText variant="subtitle1" TextSize=".875rem" fontweight="500" style={{ marginLeft: '12px', textAlign: 'left' }} noWrap> 
                              {this.TextWithEllipsis({
                                      text: this.state.finalDocFileName, maxLength: 25
                                  })}
                              </ProjectText>
                            </Box>
                          </Grid>
                          <Grid xs={4}>
                            <Box> <Box data-test-id="DeleteBusinessPdf" style={{ float: 'right', marginRight: '10px' }} onClick={() => { this.DeletePdf("finalBLDoc"); }}>
                              <img className="SmallIcon" src={RemoveIcon} alt="upload" data-test-id="DeletePdfBtn" />
                            </Box> </Box></Grid></Button>
                      </Grid>
                    )}
                    <input className="Custmizedinputs" name="finalBLDoc" id="pdf" multiple disabled={this.state.finalBLDoc.length !== 0}
                      accept=".pdf" type="file" data-test-id="FirsTFormDocument" style={{ display: "none" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.UploadFile(e, "finalBLDoc"); }} />
                  </Box>
                </Box>
                <Button onClick={this.handleFinalBLsucess} className={this.state.finalBLDoc?.length === 0 ? "disbledBtn" : "nominationButton"}
                  disabled={this.state.finalBLDoc?.length === 0 ? true : false}
                > Submit</Button>
              </Grid>
            </Grid>
          </StyledDialogModal>
        </Grid>
      )}
    </>
  }
  renderdraftBL = () => {
    return <>
      {this.state.DraftBLstate && (
        <Grid>
          <StyledDialogModal open={this.state.DraftBLstate}>
            <Grid container>
              <Grid item className="nominationClose" lg={12} md={12} sm={12} xs={12}>
                <img className="nominationCloseimg" src={AddcloseImg} data-test-id="DraftBLstate" alt=""
                  onClick={() => { this.handleClose("DraftBLstate"); }} />
              </Grid>
              <Grid className="nominationMsggrid" item lg={12} md={12} sm={12} xs={12}>
                <Typography className="nominationHeading"> Submit Draft B/L</Typography>
                <br />
                <Box >
                  <Grid item xs={6} >
                    <Typography className="verificationText" style={{ marginLeft: '25%' }}>UPLOAD FILE</Typography>
                  </Grid>
                  <Box style={{ display: "flex", justifyContent: "center", marginTop: '1%' }}>
                    {this.state.draftBLDoc?.length === 0 ? (
                      <label data-test-id="FirsTFormDocumentDragSecond" htmlFor="pdf" className="PdfClass"
                        onDragOver={(event) => this.handleDrag(event)}
                        onDrop={(event) => this.handleDropFile(event)}
                        onDragEnter={(event) => this.handleDrag(event)}
                        onDragLeave={(event) => this.handleDrag(event)}
                      >
                        <Button id="PdfFile" variant="text" size="small" component="span"
                          startIcon={<img data-test-id="DeleteBusinessPdf" alt="upload" src={upload} className="SmallIcon" />}
                          className="UploadFileBtn"
                        > <ProjectText TextSize=".875rem" textcolor="#797979" variant="subtitle1" noWrap>Browse or drag & drop file here (Only PDF)</ProjectText>
                        </Button>
                      </label>
                    ) : (
                      <Grid item xs={6} spacing={0}>
                        <Button className={`UploadFileBtn`} id="PdfFile" variant="text" size="large" component="span" style={{ display: "flex" }}>
                          <Grid xs={11}>
                            <Box className="textandPdfIcon">
                              <img alt="upload" style={{ marginLeft: '8px' }} className="SmallIcon" src={pdf} />
                              <ProjectText variant="subtitle1" fontweight="500" TextSize=".875rem" noWrap style={{ marginLeft: '12px', width: '100%', textAlign: 'left' }}> 
                              {this.TextWithEllipsis({
                                      text: this.state.FileName, maxLength: 25
                                  })}
                              </ProjectText>
                            </Box>
                          </Grid>
                          <Grid xs={1}>
                            <Box>
                              <Box data-test-id="DeleteBusinessPdf" style={{ marginRight: '10px', float: 'right' }}
                                onClick={() => { this.DeletePdf("draftBLDoc"); }}
                              >
                                <img data-test-id="DeletePdfBtn" className="SmallIcon" alt="upload" src={RemoveIcon} />
                              </Box>
                            </Box>
                          </Grid>
                        </Button>
                      </Grid>
                    )}
                    <input
                      name="draftBLDoc"
                      className="Custmizedinputs"
                      id="pdf"
                      multiple
                      accept=".pdf"
                      type="file"
                      data-test-id="FirsTFormDocument"
                      style={{ display: "none" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.UploadFile(e, "draftBLDoc");
                      }}
                      onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                        e.currentTarget.value = ''; 
                      }}
                    
                    />
                  </Box>
                </Box>
                <Button
                  onClick={this.handleVerifysucess}
                  className={
                    this.state.draftBLDoc?.length === 0
                      ? "disbledBtn"
                      : "nominationButton"
                  }
                  disabled={this.state.draftBLDoc?.length === 0 ? true : false}
                >
                  Submit
                </Button>
              </Grid>
              <br />
              {(this.state.selectedBooking?.attributes?.draft_bl_file_name != null) && <Grid
                className="nominationMsggrid"
                item
                style={{ marginTop: '4%' }}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Grid item xs={6} spacing={2} style={{ marginLeft: '25%' }}>
                  <Typography className="verificationText" style={{ textAlign: 'left' }}>
                    RECENTLY UPLOADED FILE
                  </Typography>
                  {(!this.state.selectedBooking?.attributes?.draft_bl_status && this.state.selectedBooking?.attributes?.draft_bl_status != null)&&this.state.selectedBooking?.attributes?.draft_bl_file_name.map((itemData:any,index:number)=>(<> <Button
                    className={`UploadFileBtn`}
                    id="PdfFile"
                    variant="text"
                   // disabled
                    onClick={()=>window.open(this.state.selectedBooking?.attributes?.draft_bl[index],'_blank')}
                    size="large"
                    style={{ display: "flex",marginTop:5 }}
                    component="span"
                  >
                    <Grid xs={11}>
                      <Box className="textandPdfIcon">
                        <img style={{ marginLeft: '8px' }} alt="upload" src={pdf} className="SmallIcon" />
                        <ProjectText
                          variant="subtitle1"
                          style={{ marginLeft: '12px', textAlign: 'left' }}
                          TextSize=".875rem"
                          noWrap
                          fontweight="500"
                        >
                          {this.TextWithEllipsis({text: itemData, maxLength: 27})}
                        </ProjectText>
                      </Box>
                    </Grid>
                    <Grid xs={1}>
                      <img
                        src={RemoveIcon}
                        alt="upload"
                        data-test-id="DeletePdfBtn"
                        className="SmallIcon"
                      />
                    </Grid>
                  </Button></>))}
                </Grid>
              </Grid>}
            </Grid>
          </StyledDialogModal>
        </Grid>
      )}</>
  }
  renderinvoiceBL = () => {
    return <>
      {this.state.paymentconfiguration && (
        <Grid>
          <StyledDialogModal open={this.state.paymentconfiguration}>
            <Grid container>
              <Grid
                item
                className="nominationClose"
                lg={12} md={12} sm={12} xs={12}>
                <img
                  className="nominationCloseimg"
                  src={AddcloseImg}
                  data-test-id="paymentconfiguration"
                  alt=""
                  onClick={() => {
                    this.handleClose("paymentconfiguration");
                  }}
                />
              </Grid>

              <Grid
                className="nominationMsggrid"
                item
                lg={12} md={12} sm={12}xs={12}
                 >
                <Typography className="nominationHeading">
                  Send Invoice
                </Typography>
                <Box style={{ marginTop: "2%" }}>
                  <Grid item xs={6} >
                    <Typography className="verificationText" style={{ marginLeft: '25%' }}>
                      UPLOAD FILE
                    </Typography>
                  </Grid>
                  <Box style={{ display: "flex", justifyContent: "center" }}
                  >
                    {this.state.invoiceDoc?.length === 0 ? (
                      <label
                        onDragOver={(event) => this.handleDrag(event)}
                        data-test-id="FirsTFormDocumentDragThird"
                        onDragEnter={(event) => this.handleDrag(event)}
                        onDrop={(event) => this.handleDropFile(event)}
                        onDragLeave={(event) => this.handleDrag(event)}
                        htmlFor="pdf"
                      >
                        <Button
                          fullWidth
                          id="PdfFile"
                          component="span"
                          variant="text"
                          className="UploadFileBtn"
                          size="large"
                          startIcon={
                            <img
                              src={upload}
                              alt="upload"
                              data-test-id="DeleteBusinessPdf"
                              className="SmallIcon"
                            />
                          }
                        >
                          <ProjectText
                            textcolor="#797979"
                            variant="subtitle1"
                            TextSize=".875rem"
                            noWrap
                          >
                            Browse or drag & drop file here (Only PDF)
                          </ProjectText>
                        </Button>
                      </label>
                    ) : (
                      <Grid item xs={6} spacing={0}>
                        <Button
                          className={`UploadFileBtn`}
                          fullWidth
                          id="PdfFile"
                          component="span"
                          size="large"
                          variant="text"
                        >
                          <Grid xs={11}>
                            <Box className="textandPdfIcon" >
                              {/* <Box className="textandPdfIcon"> */}
                              <img
                                alt="upload"
                                src={pdf}
                                className="SmallIcon"
                                style={{ marginLeft: '8px' }}
                              />
                              <ProjectText
                                TextSize=".875rem"
                                fontweight="500"
                                variant="subtitle1"
                                style={{ marginLeft: '12px' }}
                                noWrap
                              >
                                {this.TextWithEllipsis({
                                      text: this.state.invoiceDocFileName, maxLength: 25
                                  })}
                              </ProjectText>
                            </Box>
                          </Grid>
                          <Grid xs={1}>

                            <Box>
                              <Box
                                onClick={() => {
                                  this.DeletePdf("invoiceDoc");
                                }}
                                data-test-id="DeleteBusinessPdf"
                                style={{ float: 'right', marginRight: '10px' }}
                              >
                                <img
                                  src={RemoveIcon}
                                  alt="upload"
                                  data-test-id="DeletePdfBtn"
                                  className="SmallIcon"
                                />
                              </Box>
                            </Box>
                          </Grid>

                        </Button>
                      </Grid>
                    )}
                    <input
                      className="Custmizedinputs"
                      name="invoiceDoc"
                      multiple
                      id="pdf"
                      type="file"
                      disabled={this.state.invoiceDoc.length !== 0}
                      accept=".pdf"
                      style={{ display: "none" }}
                      data-test-id="FirsTFormDocument"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.UploadFile(e, "invoiceDoc");
                      }}
                      onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                        e.currentTarget.value = ''; 
                      }}
                    
                    />
                  </Box>
                </Box>
                <br />
                <Grid
                  className="nominationMsggrid"
                  lg={12} md={12} sm={12} xs={12}
                  item
                >
                  <div className="termsDiv">
                    <h4 className="termsheading">Remarks</h4>{" "}
                    {/* <Button
                      style={{
                        padding: "0px 30px 0px 3px",
                        marginTop: "-2px"
                      }}
                      onMouseEnter={(e) => this.setState({ termsPopup: true, anchorElterms: e.currentTarget })}
                      onMouseLeave={() => this.setState({ termsPopup: false })}
                    >
                      <img src={terms} className="TermsIcon" />
                    </Button> */}

                    <Popper
                      open={this.state.termsPopup}
                      anchorEl={this.state.anchorElterms}
                      placement="right"
                      transition
                      disablePortal
                      style={{ zIndex: 999 }}
                    >
                      <Typography
                        style={{
                          textAlign: "center",
                          color: "#1E1E1E",
                          fontFamily: "Asap",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "20px",
                          backgroundColor: "white",
                          borderRadius: "4px",
                          padding: "10px 10px",
                          width: "fit-content",
                          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                          cursor: 'pointer'
                        }}
                        className="iconcontent"
                        onClick={() => this.setState({ termsPopup: !this.state.termsPopup })
                        }
                      >
                        If not already started in invoice
                      </Typography>
                    </Popper>
                  </div>
                  <input
                    className="refrence-inputTerms"
                    type="text"
                    value={this.state.sellerTerm}
                    onChange={(e) =>
                      this.setState({ sellerTerm: e.target.value })
                    }
                  />
                </Grid>
                <Button
                  onClick={this.handleSubmitsucess}
                  className={
                    this.state.invoiceDoc?.length === 0
                      ? "disbledBtn"
                      : this.InvoiceClass()
                  }
                  disabled={this.state.invoiceDoc?.length === 0 ? true : this.InvoiceInput()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </StyledDialogModal>
        </Grid>
      )}</>
  }

  renderPopups = () => {
    return (
      <>
        {/* Nomination Modal Start for seller */}

        {this.state.BookingrefrenceModal && (
          <StyledDialogModal open={this.state.BookingrefrenceModal}>
            <Grid container>
              <Grid
                item
                className="nominationClose"
                lg={12} md={12} sm={12} xs={12}
              >
                <img
                  className="nominationCloseimg"
                  data-testid="closeBookingRefmodal"
                  src={AddcloseImg}
                  alt=""
                  onClick={() => {
                    this.handleClose("BookingrefrenceModal");
                  }}
                />
              </Grid>
              <Grid
                className="nominationMsggrid"
                item
                lg={12} md={12} sm={12} xs={12}
              >
                <Typography className="nominationHeading">
                  Issue Booking Reference Number
                </Typography>

                <Typography className="bookingRefrenceContent">
                  INPUT BOOKING REFERENCE NUMBER
                </Typography>

                <input
                  data-test-id="refrence"
                  type="text"
                  required
                  className="refrence-input"
                  onChange={(e) =>
                    this.setState({ BookingrefrenceNumber: e.target.value })
                  }
                />
              </Grid>
              <Grid
                className="nominationMsggrid"
                item
                lg={12} md={12} sm={12} xs={12}
              >
                <Button
                  data-test-id="SubmitRefrenceNumber"
                  disabled={!this.state.BookingrefrenceNumber ? true : false}
                  className={
                    this.state.BookingrefrenceNumber
                      ? "nominationButton"
                      : "disbledBtn"
                  }
                  onClick={() => {
                    this.SubmitRefrenceNumber();
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </StyledDialogModal>
        )}

        {this.state.approvalModal && (
          <StyledDialogModal open={this.state.approvalModal}>
            <Grid container>
              <Grid
                item
                lg={12}
                className="nominationClose"
                md={12}
                xs={12}
                sm={12}
              >
                <img
                  className="nominationCloseimg"
                  onClick={() => {
                    this.handleClose("approvalModal");
                  }}
                  src={AddcloseImg}
                  data-testid="closeApprovalmodal"
                  alt=""

                />
              </Grid>
              <Grid
                className="nominationMsggrid"
                xs={12}
                item
                md={12}
                lg={12}
                sm={12}
              >
                <img
                    className="nominationErrorimg"
                    src={resultNotFound1}
                    alt=""
                  />
                <Typography className="nominationHeading">
                  {" "}
                  Booking Confirmation
                </Typography>
                <Typography className="AuthorizeContent">
                Once confirmed, cancellation charges may occur. Do you wish to confirm this booking?
                </Typography>
                <Button
                  className="nominationButton1"
                  onClick={this.approvalConfirmation}
                >
                  CONFIRM
                </Button>
                <Button
                  className="notYet"
                  onClick={this.askForCancellationReason}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          </StyledDialogModal>
        )}

        {this.state.approvalConfirmationModal && (
          <StyledDialogModal open={this.state.approvalConfirmationModal}>
            <Grid container>
              <Grid
                item
                lg={12}
                className="nominationClose"
                md={12}
                xs={12}
                sm={12}
              >
                <img
                  className="nominationCloseimg"
                  onClick={() => {
                    this.closeApprovalConfirmedModal()
                  }}
                  src={AddcloseImg}
                  data-testid="closeNominatemodal"
                  alt=""

                />
              </Grid>
              <Grid
                className="nominationMsggrid"
                xs={12}
                item
                md={12}
                lg={12}
                sm={12}
              >
                <img
                    className="nominationErrorimg"
                    src={image_success}
                    alt=""
                  />
                <Typography className="nominationHeading">
                  {" "}
                  Booking Confirmed
                </Typography>
                <Typography className="AuthorizeContent">
                Your booking has been confirmed. You may now proceed to Authorize Container Operator.
                </Typography>
              </Grid>
            </Grid>
          </StyledDialogModal>
        )}

      {this.state.approvalReasonModal && (
        <Grid>
          <StyledDialogModal open={this.state.approvalReasonModal}>
            <Grid container>
              <Grid
                item
                className="nominationClose"
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <img
                  className="nominationCloseimg"
                  src={AddcloseImg}
                  alt=""
                  data-testid="revisionClosemodal"
                  onClick={() => {
                    this.closeApprovalReasonModal()
                  }}
                />
              </Grid>
              <Grid
                className="nominationMsggrid"
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <img className="nominationErrorimg" src={blueQue} alt="" />
                <Typography className="nominationHeading">
                  Cancellation Reason
                </Typography>
                <Typography className="revisionText">
                  We will notify the buyer. Kindly provide the reason for cancelling this booking
                </Typography>
                <Box className="reasonBox">
                  <Typography className="revisionText">
                    REASON FOR CANCELLATION
                  </Typography>
                  <TextField onChange={(e) => this.handleReasonFieldChange(e)} variant="outlined" 
                    required
                    InputProps={{className: "reasonTextfield"}}                          
                    style={{color:'#000',fontWeight:'bold',width:'100%'}}                  
                  />
                </Box>
                <Button
                  className="nominationButton2"
                  data-testid="sucessRevisonnmodal"
                  onClick={this.approvalCancellation}
                  style={this.state.reasonText && this.state.reasonText !== "" ? {backgroundColor: '#1653dd'} : {backgroundColor: "grey"}}
                >
                  CANCEL BOOKING
                </Button>
              </Grid> 
            </Grid>
          </StyledDialogModal>
        </Grid>
      )}

        {this.state.approvalCancelledModal && (
          <StyledDialogModal open={this.state.approvalCancelledModal}>
            <Grid container>
              <Grid
                item
                lg={12}
                className="nominationClose"
                md={12}
                xs={12}
                sm={12}
              >
                <img
                  className="nominationCloseimg"
                  onClick={() => {
                    this.closeApprovalCancelledModal()
                  }}
                  src={AddcloseImg}
                  data-testid="closeNominatemodal"
                  alt=""

                />
              </Grid>
              <Grid
                className="nominationMsggrid"
                xs={12}
                item
                md={12}
                lg={12}
                sm={12}
              >
                <img
                    className="nominationErrorimg"
                    src={reject}
                    alt="Cross"
                  />
                <Typography className="nominationHeading">
                  {" "}
                  Booking Cancelled
                </Typography>
                <Typography className="AuthorizeContent">
                Your booking has been cancelled.
                </Typography>
              </Grid>
            </Grid>
          </StyledDialogModal>
        )}

        {this.state.nominationModal && (
          <StyledDialogModal open={this.state.nominationModal}>
            <Grid container>
              <Grid
                item
                lg={12}
                className="nominationClose"
                md={12}
                xs={12}
                sm={12}
              >
                <img
                  className="nominationCloseimg"
                  onClick={() => {
                    this.handleClose("nominationModal");
                  }}
                  src={AddcloseImg}
                  data-testid="closeNominatemodal"
                  alt=""

                />
              </Grid>
              <Grid
                className="nominationMsggrid"
                xs={12}
                item
                md={12}
                lg={12}
                sm={12}
              >
                <Typography className="nominationHeading">
                  {" "}
                  Authorize Container Operator
                </Typography>
                <Typography className="AuthorizeContent">
                  Authorize the Container Operator on PORTNET through this link:{" "}
                  <span className="nominationLink" style={{cursor: 'pointer'}} onClick={() => window.open(this.state.portnetUrlData)}>
                     {this.state.portnetUrlData}
                  </span> {"\n"} <br />
                  Once completed please acknowledge below.
                </Typography>
                <Button
                  className="nominationButton1"
                  onClick={this.Authorizationsucess}
                >
                  Authorization Done
                </Button>
                <Button
                  className="notYet"
                  onClick={()=>this.handleClose("nominationModal")}
                >
                  Not Yet
                </Button>
              </Grid>
            </Grid>
          </StyledDialogModal>
        )}

        {this.state.submittingState && (
          <Grid>
            <StyledDialogModal open={this.state.submittingState}>
              <Grid container>
                <Grid
                  item
                  className="nominationClose"
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <img
                    className="nominationCloseimg"
                    src={AddcloseImg}
                    data-test-id="submittingState"
                    alt=""
                    onClick={() => {
                      this.handleClose("submittingState");
                    }}
                  />
                </Grid>
                <Grid
                  className="nominationMsggrid"
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Typography className="nominationHeading">
                    Submit Shipping Instructions
                  </Typography>

                  <Button
                    className="nominationButton"
                    onClick={this.handleSubmitsucess}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </StyledDialogModal>
          </Grid>
        )}


        {this.state.revisionrequest && (
          <Grid>
            <StyledDialogModal open={this.state.revisionrequest}>
              <Grid container>
                <Grid
                  item
                  className="nominationClose"
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <img
                    className="nominationCloseimg"
                    src={AddcloseImg}
                    data-test-id="revisionrequest"
                    alt=""
                    onClick={() => {
                      this.handleClose("revisionrequest");
                    }}
                  />
                </Grid>
                <Grid
                  className="nominationMsggrid"
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <img
                    className="nominationErrorimg"
                    src={resultNotFound}
                    alt=""
                  />
                  <Typography className="nominationHeading">
                    Request Revision
                  </Typography>
                  <Typography className="nominationHeading">
                    We will notified the seller! please enter the reason! Why
                    are you requesting revision?
                  </Typography>
                  <Typography className="nominationHeading">
                    REASON FOR REVISION
                  </Typography>
                  <TextField />
                  <Button
                    className="nominationButton"
                    onClick={this.handleSubmitsucess}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </StyledDialogModal>
          </Grid>
        )}

        {this.renderdraftBL()}
        {this.renderinvoiceBL()}
        {this.renderFinalBL()}

        {/*Successful messages Modal Start */}
        {this.state.BookingrefrenceSuccess && (
          <StyledDialogModal open={this.state.BookingrefrenceSuccess}>
            {this.sucessmessage(
              "Booking Reference No. Issued!",
              "We've notified the buyer! You will be notified once the buyer submits the shipping instructions"
            )}
          </StyledDialogModal>
        )}

        {this.state.authorizationModal && (
          <StyledDialogModal open={this.state.authorizationModal}>
            {this.sucessmessage(
              "Authorization Completed!",
              "We've notified the buyer! You will be notified once the buyer nominates."
            )}
          </StyledDialogModal>
        )}

        {this.state.verifySucess && (
          <StyledDialogModal open={this.state.verifySucess}>
            {this.sucessmessage(
              "Submission Successful!",
              "We've notified the buyer! You will be notified once the buyer confirms the B/L."
            )}
          </StyledDialogModal>
        )}

        {this.state.paymentSucess && (
          <StyledDialogModal open={this.state.paymentSucess}>
            {this.sucessmessage(
              "Invoice Sent!",
              "We've notified the buyer! You will be notified once the buyer upload the payment confirmation."
            )}
          </StyledDialogModal>
        )}

        {this.state.finalBLsuccess && (
          <StyledDialogModal open={this.state.finalBLsuccess}>
            {this.sucessmessage(
              "Submission Successful!",
              "We've notified the buyer! You will be notified once the buyer receives the final B/L."
            )}
          </StyledDialogModal>
        )}
      </>
    );
  };

  TextWithEllipsi = ({ text, maxLength }: any) => {
    if (!text || text.length <= maxLength) {
      return <span>{text}</span>;
    }

    const truncatedText = text.slice(0, maxLength) + '...';

    return (
      <span title={text}>
        {truncatedText}
      </span>
    );
  };

   darkenColor(hex:any, percent:number) {
    // Remove the '#' character if present
    hex = hex.replace('#', '');

// Convert hex to RGB
let r = parseInt(hex.substring(0, 2), 16);
let g = parseInt(hex.substring(2, 4), 16);
let b = parseInt(hex.substring(4, 6), 16);

// Darken the RGB values
r = Math.floor(r * (100 - percent) / 100);
g = Math.floor(g * (100 - percent) / 100);
b = Math.floor(b * (100 - percent) / 100);

// Ensure values are within the valid range
r = Math.min(255, Math.max(0, r));
g = Math.min(255, Math.max(0, g));
b = Math.min(255, Math.max(0, b));

// Convert RGB back to hex
return '#' + (r * 65536 + g * 256 + b).toString(16).padStart(6, '0');
}

  renderMaindiv = () => {
    return (
      <MainDiv>
        <Box className="trackingDatabox" data-test-id="TrackingList">
          {this.state.trackingData?.map((booking: any, index: number) => {
            let buttonValues = {
              label: "Confirm Booking",
              status: false,
              activeState: -1,
            };
            const principalColorCode = booking.attributes?.principal_color_code;
            const darkenedBorderColor = this.darkenColor(principalColorCode ?? '',15);
            const darkenedBorderColors = this.darkenColor(principalColorCode ?? '',50);
            if (booking.attributes.booking_confirmation_status !== "Booking Confirmed") {
              buttonValues = {
                label: "Confirm Booking",
                status: false,
                activeState: -1,
              };
            }
            if (booking.attributes.booking_confirmation_status === "Booking Confirmed" && !booking.attributes.co_authorizer) {
              buttonValues = {
                label: "Authorize co code",
                status: false,
                activeState: 0,
              };
            }
            if (booking.attributes.co_authorizer) {
              buttonValues = {
                label: "Issue Booking no",
                status: this.Co_Auth(booking),
                activeState: 1,
              };
            }
            if (booking.attributes.booking_reference_number) {
              buttonValues = {
                label: "Upload Draft B/L",
                status: this.DraftBLstatus(booking),
                activeState: 2,
              };
            }
            if (booking.attributes.draft_bl_status) {
              buttonValues = {
                label: "Upload Invoice",
                status: this.InvoiceStatus(booking),
                activeState: 3,
              };
            }
            if (!booking.attributes.draft_bl_status && booking.attributes.draft_bl_status != null) {
              buttonValues = {
                label: "Upload Draft B/L",
                status: this.DraftBLstatus(booking),
                activeState: 2,
              };
            }
            if (booking.attributes.payment_status) {
              buttonValues = {
                label: "Upload Final b/l",
                status: this.paymentStatus(booking),
                activeState: 4,
              };
            }
            if (booking.attributes.payment_status) {
              buttonValues = {
                label: "Upload Final b/l",
                status: this.CheckAtuDate(booking),
                activeState: 4,
              };
            }
            if (booking.attributes.final_bl) {
              buttonValues = {
                label: "Booking Completed",
                status: true,
                activeState: 5,
              };
            }

            const cancelButtonTitle = this.state.selectedBooking?.attributes?.booking_confirmation_status == "Booking Cancelled" ? "Cancelled By Seller" : "Booking Cancelled";

            return (
              <Grid
                container
                className="trackingDatacontainer"
                key={booking.id}
              >
                <Grid
                  className="trackingDatagrid"
                  item
                  lg={6}
                >
                  <Grid className={"boxPart1"} 
                  style={{borderLeftColor: darkenedBorderColor,backgroundColor:booking.attributes?.principal_color_code}}>
                    <Grid item className="box" lg={12} md={12} sm={12} xs={12}>
                      <Typography className="boxText"
                      style={{border: `1px solid ${darkenedBorderColors}`,color:darkenedBorderColors,backgroundColor:principalColorCode}}
                      >
                        {booking.attributes.seller_principal_org_code || booking.attributes.seller_org_code || booking.attributes.principal_org_code || booking.attributes.org_code}
                      </Typography>
                      <Typography className="boxTextid">
                        ID:{booking.attributes.booking_uid}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{width:"100%"}}>
                      <Box className={"firstSecondPart"}>
                        <Box className={"mbottom"}>
                          <Typography className={"first"}>
                            {`${moment(booking.attributes.etb).format(
                              "D MMM"
                            )} (${moment(booking.attributes.etb).format(
                              "ddd"
                            )})`}
                          </Typography>
                            <Typography className={"second"}>
                              {booking.attributes.pol}
                            </Typography>
                            <Typography className={"third"}>
                              {booking.attributes?.pol_terminal}
                            </Typography>
                        </Box>
                        <Box className={"middlepartoffirstbox"}>
                          <Box className={"trainPart"}>
                            <img src={Matches} alt="" className="trainImage" />
                            <Typography variant="h6" className={"trainPart"}>
                              {
                                this.TextWithEllipsi({
                                  text: booking.attributes.vessel_voyage && booking.attributes.in_voyage &&
                                    `${booking.attributes.vessel_voyage} / In- ${booking.attributes.in_voyage} Out- ${booking.attributes.out_voyage}`,
                                  maxLength: 25
                                })
                              }
                            </Typography>
                          </Box>
                          <img src={arraw} alt="" style={{ width: "95%" }} />
                          {this.sellerQuantitySize(booking)}
                        </Box>
                        <Box className={"mbottom"}>
                          <Typography className={"first"}>
                            {`${moment(booking.attributes.eta).format(
                              "D MMM"
                            )} (${moment(booking.attributes.eta).format(
                              "ddd"
                            )})`}
                          </Typography>
                          <Typography variant="h3" className={"second"}>
                            {booking.attributes.pod}
                          </Typography>
                          <Typography className={"third"}>
                            {booking.attributes.pod_terminal}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  className="trackingDatagrid"
                  item
                  lg={6}
                >
                  <Box className={"secondBoxMain"}>
                    <CustomizedSteppers
                      newStepperState={buttonValues.activeState}
                      data-test-id="MatchingFlowTest"
                    />
                    <Box className="nominates_icon">
                      {
                        booking.attributes.booking_status == "rejected" ?
                          <Button
                            variant="contained"
                            disabled={true}
                            data-testid="openTrakingmodal"
                            className="mainButtondisable"
                          >
                            {cancelButtonTitle}
                          </Button>
                          :
                          <Button
                            variant="contained"
                            data-testid="openTrakingmodal"
                            onClick={() => {
                              this.setState({ selectedBooking: booking });
                              this.handleOpentrackingModal(
                                buttonValues.label,
                                booking
                              );
                            }}
                            disabled={buttonValues.status}
                            className={
                              buttonValues.status
                                ? "mainButtondisable"
                                : "mainButton1"
                            }
                          >
                            {buttonValues.label}
                          </Button>
                      }
                      <Box>
                        <Button
                          data-testid="openPopover"
                          onClick={(e) => this.handleClickAway(e, booking)}
                          className="moreIcon"
                        >
                          <MoreVertIcon />
                        </Button>
                        {this.state.popup && (
                          <ClickAwayListener
                            onClickAway={() => this.setState({ popup: false })}
                            data-test-id="sellerPopover"
                          >
                        <Popper
                          open={this.state.popup}
                          anchorEl={this.state.anchorEl}
                          placement="left"
                          className="popperStyle"
                        >
                          <Typography
                            style={{
                              textAlign: "center",
                              color: "#1E1E1E",
                              fontFamily: "Asap",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "20px",
                              textTransform: "uppercase",
                              backgroundColor: "white",
                              borderRadius: "4px",
                              padding: "10px 10px",
                              width: "fit-content",
                              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                              cursor: 'pointer'
                            }}
                            className="iconcontent"
                            onClick={() => {
                              this.setState(prevState => ({ popup: !this.state.popup }))
                              this.TrackModelViewUpdate()
                            }}
                          >
                            VIEW DETAILS
                          </Typography>
                        </Popper>
                        </ClickAwayListener>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            );
          })}
        </Box>
        {(this.state.filterData && this.state.trackingData?.length==0)&& <div  style={{...webStyle.noData, flexDirection: "column"}}>
              <img
                src={resultNotFound1}
                alt="resultNotFound1"
                style={webStyle.noDataImg}
              />
              <p style={{...webStyle.head,fontWeight: "bold"}}>No Results Found!</p>
              <p  style={{...webStyle.msg}}>Change or Remove Filters and Try Again.</p>
            </div>}
      </MainDiv>
    );
  };
  sellerQuantitySize = (booking: any) => {
    return (
      <Typography>
        {booking.attributes.offer_quantity && 
        <>
        <span
          style={{
            fontWeight: "bold",
            fontFamily: "Asap",
          }}
        >
          {booking.attributes.offer_quantity}
        </span>{" "}
        <span style={{ fontWeight: 700 }}>x</span>
        <span className={"containerSize"}> {booking.attributes.container_size} </span>
       {booking.attributes.offer_sec_quantity && <span style={{ fontWeight: "bold" }}>,</span>}
       </>}
        {booking.attributes.offer_sec_quantity &&
          <>
            <span
              style={{
                fontFamily: "Asap",
                fontWeight: "bold",
              }}
            >{booking.attributes.offer_sec_quantity}
            </span>
            <span> x <span className={"containerSize"}>{booking.attributes.sec_container_sizes}
            </span>
            </span>
          </>}{" "}     <span style={{fontFamily: "Asap",fontWeight:600}}>{booking.attributes.container_type}</span>
      </Typography>
    )
  }
  MapData = () => {
    // Extracting state variables
    const activeSlotFilter = this.state.activeSlotFilter;
    let text = "";
    let backgroundColor = activeSlotFilter[0]?.attributes?.color_name;
  
    // Building the text and backgroundColor strings
    activeSlotFilter.forEach((slot: any) => {
      text += `${slot?.attributes?.name?.trim().split(" ").join("/")} ${slot?.attributes?.code} `;
      if (slot?.attributes?.color_name)  
      {
        backgroundColor += ` ${slot.attributes?.color_name} `; 
      }});    
    // Handling empty state case
    if (activeSlotFilter.length === 0) {
      return Array.isArray(this.state.prinicpalOrgList) ?  "ALL" 
      : this.state.prinicpalOrgList.type === "organization" ? 
      <>
      <span>{this.state.prinicpalOrgList?.attributes?.name}
      </span>
      <p  style={webStyle.boxtextp}>
          {this.state.prinicpalOrgList?.attributes?.code} </p>
  </>
       : "ALL"
     }
  
    // Truncating text if necessary
    const truncatedText = text.length > 28  ? text.slice(0, 28) + " ..." 
    : text;
  
    return (
      <div  
      className="DropDownDiv" title={text?.split("/").join(" ")}
      >
        {truncatedText.split(" ").filter(txt => txt !== "").map((txt, index) => {
          if (index % 2 === 0) {
            return (
              <h3 
              className="DropDownDivh3" 
              key={index}>      {txt?.split("/").join(" ")} 
              </h3>
            );
          } else {
            return (
              <p className="DropDownDivP" 
                key={index}
                style={{padding: ".25rem .625rem", borderRadius: ".25rem",  
                  backgroundColor: backgroundColor?.split(" ").filter((txt:any) => txt !== "")[index],
                }}> {txt}
              </p>  );    
              }
        } )
        }
      </div> 
      );
  };
  FristGrid = () => {
   this.MapData()

    const MapDataElse = () => {
      return (
        <div className="DropDownDiv">
          <h3 className="DropDownDivh3">{this.state.activeSlotFilter?.attributes?.name}</h3>
          <p className="DropDownDivP" style={{ padding: '.25rem .625rem', borderRadius: '.25rem', backgroundColor: this.state.activeSlotFilter?.attributes?.color_name }}>{this.state.activeSlotFilter?.attributes?.code}</p>
        </div>
      )
    }

    return (
      <Grid container alignItems="center" style={webStyle.slotsSection}>
        <Grid item lg={6} md={6}>
          <SectionWrapperLeft style={webStyle.leftBar}>
            <StyledSwitch>
              <div className="switch" onClick={() => this.SwitchChnages()} data-test-id="switch-changes">
                <input
                  className="Inputswitch"
                  type="checkbox"
                  checked={this.state.switch}
                />
                <span className="slider round">
                  <ProjectText TextSize="1rem" fontweight="700" className="text">
                    {this.state.switch ? "SELL" : ""}
                  </ProjectText>
                </span>
              </div>
            </StyledSwitch>

            <h1 style={webStyle.slotsFor}>Slots for</h1>
            <div style={{ position: 'relative', color: 'rgba(30, 30, 30, 1)', fontFamily: 'Asap', fontSize: '.875rem', fontWeight: 500 }}>
              <div data-test-id="organizationFilter" onClick={() => this.setState({ isSlotFilterOpen: !this.state.isSlotFilterOpen })}
                className="selectSlotInput" style={webStyle.selectSlot}>
                <div style={{ fontWeight: 500, display: 'flex', overflow: 'auto',alignItems:"center" }}>
                  {Array.isArray(this.state.activeSlotFilter) ?
                    this.MapData() : <MapDataElse />}
                </div>
                <img style={{width: '10px', height: '10px', objectFit: 'contain'}} src={image_dropdown} alt="dropdown" />
              </div>
              {this.state.isSlotFilterOpen && (
                <ClickAwayListener onClickAway={() => this.setState({ isSlotFilterOpen: false })}
                >
                  <Box data-test-id="organizationFilterDropdown">                  
                    {Array.isArray(this.state.prinicpalOrgList) ? (
                    <div  className="selectSlotDropdown"
                     style={webStyle.selectSlotDropdown}
                     >
                     {this.state.prinicpalOrgList?.map((val: any) => {
                      return (
                        <div 
                        key={val.id} style={{ borderLeft: `.25rem solid ${val.attributes.color_name}`, 
                        ...webStyle.selectSlotItem }}
                        >
                          <h4 style={{ textTransform: 'uppercase' }}>
                            {val.attributes.name}
                          </h4>
                          <Checkbox color="primary" checked={this.state.activeSlotFilter.includes(val)}
                            data-test-id="organizationFilterDropdownCheck"
                            onChange={(e) => this.handleSlotFilter(val, e.target.checked)} />
                        </div>
                     )
                    })
                     }
                       </div>)
                    : this.state.prinicpalOrgList.type !== "organization" && (
                      <div data-test-id="organizationFilterDropdown" className="selectSlotDropdown"
                     style={webStyle.selectSlotDropdown}
                     >
                    <div   key={this.state.prinicpalOrgList.id}
                          style={{ borderLeft: `.25rem solid ${this.state.prinicpalOrgList.attributes.color_name}`, ...webStyle.selectSlotItem }}
                          >
                          <h4 style={{ textTransform: 'uppercase' }}> {this.state.prinicpalOrgList.attributes.name}
                          </h4>
                          <Checkbox 
                          color="primary" checked=
                          {this.state.activeSlotFilter.includes(this.state.prinicpalOrgList)}
                          onChange={(e) => this.handleSlotFilter(this.state.prinicpalOrgList, e.target.checked)}
                          /> </div>
                        </div> )               
                    } </Box>
                </ClickAwayListener>
              )}
            </div>
          </SectionWrapperLeft>
        </Grid>
        <Grid item xs={12} lg={6} md={6} style={webStyle.textEnd}>
          <AddListing />
        </Grid>
      </Grid>
    )
  }

  FilterContainerRedner = () => {
    return (
      <CustomBox>
        <FormControl style={{ width: "100%", marginRight: ".9375rem" }}>
          <StyledInputLable shrink>BOOKING ID</StyledInputLable>
          <Box
            style={{ marginTop: "1.5rem" }}>
            <StyledBox
              data-test-id="listingfilter" onClick={() => this.handleOfferExpanded("bookingID")}
            >
              <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.offerExpanded ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleOfferExpanded("listingID")} />
                  </DropdownArrowBox>
            </StyledBox>
            {this.state.offerExpanded && (
              <ClickAwayListener
                data-test-id="onClickAwayTwo"
                onClickAway={() => this.setState({ offerExpanded: false })}
              >
                <Collapse
                  in={this.state.offerExpanded}
                  disableStrictModeCompat={false}
                  timeout="auto"
                  unmountOnExit
                  style={{
                    display: "flex",
                    position: "absolute",
                    width: "100%",
                    background: "#fff",
                    zIndex: 1,
                    flexDirection: "column", height: "130px", overflowY: "auto", overflowX: "hidden"
                  }}
                >
                  {this.state.filter?.bookingID?.map(
                    (item: { label: string; isChecked: boolean }, index: number) => {
                      return (
                        <CustomFormControlLabel
                          key={item.label}
                          control={
                            <Checkbox
                              data-test-id={`bookingID${index}`}
                              color="primary"
                              checked={item.isChecked}
                              onClick={(e) => this.handleOfferChange(e, this.state.filter.bookingID, "bookingID")} style={{ paddingRight: "1.875rem" }} name={item.label} />}
                          labelPlacement="start" label={item.label}
                          style={{
                            display: "flex", justifyContent: "space-between", flexDirection: "row-reverse",
                            fontFamily: "Asap", fontSize: ".875rem",
                          }}
                        />
                      );
                    }
                  )}
                </Collapse>
              </ClickAwayListener>
            )}
          </Box>
          <Box
            style={{
              padding: ".125rem",
              width: "13.75rem",
              display: "flex", justifyContent: "start", flexWrap: "wrap", gap: ".3125rem", marginTop: ".1875rem",
              listStyle: "none",
            }}
          >
            {this.state.filter.bookingID.map(
              (option: { label: string; isChecked: boolean }) => {
                if (option.isChecked) {
                  return (
                    <li
                      style={{ fontFamily: "Asap", fontSize: ".875rem" }}
                      key={option.label}
                    >
                      <StyledChip
                        icon={<CancelIcon data-test-id={`CancelIcon`} onClick={() => {
                          this.handleOfferDelete(
                            option,
                            this.state.filter.bookingID,
                            "bookingID"
                          )
                        }} />}
                        data-test-id="listingIdChip"
                        label={option.label}
                      />
                    </li>
                  );
                }
              }
            )}
          </Box>
        </FormControl>
        <FormControl style={{ width: "100%", marginRight: ".9375rem" }}>
          <StyledInputLable shrink>VESSEL/VOY</StyledInputLable>{" "}
          <Box style={{ marginTop: "1.5rem" }}>
            <StyledBox data-test-id="typefilter" onClick={() => this.handleOfferExpanded("vessel")}>
            <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.offerExpandedtype ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleOfferExpanded("vessel")} />
                  </DropdownArrowBox>
            </StyledBox>
            {this.state.offerExpandedtype && (
              <ClickAwayListener
                data-test-id="onClickAwayThree"
                onClickAway={() => this.setState({ offerExpandedtype: false })}
              >
                <Collapse
                  in={this.state.offerExpandedtype}
                  timeout="auto"
                  unmountOnExit
                  disableStrictModeCompat={false}
                  style={{
                    zIndex: 1,
                    width: "100%",
                    background: "#fff",
                    display: "flex",
                    position: "absolute",
                    flexDirection: "column", height: "130px", overflowY: "auto", overflowX: "hidden"
                  }}
                >
                  {this.state.filter?.vessel?.map(
                   (item: { label: string; id?:number; isChecked: boolean }, index: number) => {
                    return (
                      <CustomFormControlLabel
                        key={item.id}
                        control={
                          <Checkbox
                            checked={item.isChecked}
                            color="primary"
                            data-test-id={`typefilterVESSEL/VOY${index}`}
                            onClick={(e) => this.handleOfferChange(e, this.state.filter.vessel, "vessel")}
                            style={{ paddingRight: "1.875rem" }}
                            name={item.label}
                          />
                          }
                          label={item.label}
                          labelPlacement="start"
                          style={{
                            justifyContent: "space-between",
                            fontSize: ".875rem",
                            display: "flex",
                            flexDirection: "row-reverse",
                            fontFamily: "Asap",
                          }}
                        />
                      );
                    }
                  )}
                </Collapse>
              </ClickAwayListener>
            )}
          </Box>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: ".1875rem",
              width: "13.75rem",
              justifyContent: "start",
              gap: ".3125rem",
              listStyle: "none",
              padding: ".125rem",
            }}
          >
            {this.state.filter.vessel.map(
              (option: { label: string; isChecked: boolean }, index: number) => {
                if (option.isChecked) {
                  return (
                    <li
                      style={{ fontFamily: "Asap", fontSize: ".875rem" }}
                      key={option.label}
                    >
                      <StyledChip
                        icon={<CancelIcon data-test-id={`deltevessel${index}`} onClick={() => {
                          this.handleOfferDelete(
                            option,
                            this.state.filter.vessel,
                            "vessel"
                          )
                        }} />}
                        label={option.label}
                      />
                    </li>
                  );
                }
              }
            )}
          </Box>
        </FormControl>
        <FormControl style={{ width: "100%", marginRight: ".9375rem" }}>
          <StyledInputLable shrink>
            POL
          </StyledInputLable>
          <Box style={{ marginTop: "1.5rem" }}>
            <StyledBox data-test-id="polfilter"
              onClick={() => this.handleOfferExpanded("pol")}>
                <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.offerExpandedPol ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleOfferExpanded("pol")} />
                  </DropdownArrowBox>
              </StyledBox>
            {this.state.offerExpandedPol && (
              <ClickAwayListener
                data-test-id="onClickAwayFour"
                onClickAway={() => this.setState({ offerExpandedPol: false })}
              >
                <Collapse
                  in={this.state.offerExpandedPol}
                  timeout="auto"
                  unmountOnExit
                  disableStrictModeCompat={false}
                  style={{
                    zIndex: 999, width: "100%", background: "#fff", display: "flex",
                    position: "absolute",
                    flexDirection: "column", height: "130px", overflowY: "auto", overflowX: "hidden"
                  }}
                >
                  {this.state.filter?.pol?.map(
                    (item: {
                      label: string;
                      isChecked: boolean
                    }, index: number) => {
                      return (
                        <CustomFormControlLabel
                          key={item.label}
                          control={
                            <Checkbox
                              data-test-id={"polcheck" + index}
                              color="primary"
                              checked={item.isChecked}
                              onClick={(e) => this.handleOfferChange(e, this.state.filter.pol, "pol")}
                              name={item.label}
                              style={{ paddingRight: "1.875rem" }}
                            />
                          }
                          label={item.label}
                          style={{
                            fontFamily: "Asap",
                            display: "flex",
                            flexDirection: "row-reverse",
                            justifyContent: "space-between",
                            fontSize: ".875rem",
                          }}
                          labelPlacement="start"
                        />
                      );
                    }
                  )}
                </Collapse>
              </ClickAwayListener>
            )}
          </Box>
          <Box
            style={{
              gap: ".3125rem",
              marginTop: ".1875rem",
              width: "13.75rem",
              display: "flex",
              justifyContent: "start",
              flexWrap: "wrap",
              listStyle: "none",
              padding: ".125rem",
            }}
          >
            {this.state.filter.pol.map(
              (option: {
                label: string; isChecked: boolean
              }, index: number) => {
                if (option.isChecked) {
                  return (
                    <li
                      key={option.label}
                      style={{
                        fontFamily: "Asap",
                        fontSize: ".875rem"
                      }}
                    >
                      <StyledChip
                        icon={<CancelIcon data-test-id={`POL${index}`} onClick={() => {
                          this.handleOfferDelete(
                            option,
                            this.state.filter.pol,
                            "pol"
                          )
                        }} />}
                        label={option.label}
                      />
                    </li>
                  );
                }
              }
            )}
          </Box>
        </FormControl>
        <FormControl style={{
          width: "100%",
          marginRight: ".9375rem"
        }}>
          <StyledInputLable shrink>
            POD
          </StyledInputLable>
          <Box style={{ marginTop: "1.5rem" }}>
            <StyledBox data-test-id="podfilter"
              onClick={() => this.handleOfferExpanded("pod")}>
                <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.offerExpandedPod ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleOfferExpanded("pod")} />
                  </DropdownArrowBox>
              </StyledBox>
            {this.state.offerExpandedPod && (
              <ClickAwayListener
                data-test-id="onClickAwayFive"
                onClickAway={() => this.setState({ offerExpandedPod: false })}
              >
                <Collapse
                  disableStrictModeCompat={false}
                  in={this.state.offerExpandedPod}
                  timeout="auto"
                  unmountOnExit
                  style={{
                    background: "#fff",
                    position: "absolute",
                    zIndex: 999,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column", height: "130px", overflowY: "auto", overflowX: "hidden"
                  }}
                >
                  {this.state.filter?.pod?.map(
                    (item: {
                      label: string;
                      isChecked: boolean
                    },
                      index: number) => {
                      return (
                        <CustomFormControlLabel
                          key={item.label}
                          control={
                            <Checkbox
                              data-test-id={"podcheck" + index}
                              color="primary"
                              checked={item.isChecked}
                              onClick={(e) => this.handleOfferChange(e, this.state.filter.pod, "pod")}
                              style={{ paddingRight: "1.875rem" }}
                              name={item.label}
                            />
                          }
                          labelPlacement="start"
                          label={item.label}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontFamily: "Asap",
                            fontSize: ".875rem",
                            flexDirection: "row-reverse",
                          }}
                        />
                      );
                    }
                  )}
                </Collapse>
              </ClickAwayListener>

            )}
          </Box>
          <Box
            style={{
              gap: ".3125rem",
              width: "13.75rem",
              padding: ".125rem",
              display: "flex",
              justifyContent: "start",
              flexWrap: "wrap",
              listStyle: "none",
              marginTop: ".1875rem",
            }}
          >
            {this.state.filter.pod.map(
              (option: { label: string; isChecked: boolean }, index: number) => {
                if (option.isChecked) {
                  return (
                    <li
                      style={{ fontFamily: "Asap", fontSize: ".875rem" }}
                      key={option.label}
                    >
                      <StyledChip
                        label={option.label}
                        icon={<CancelIcon
                          data-test-id={`PodDelete${index}`}
                          onClick={() => {
                            this.handleOfferDelete(
                              option,
                              this.state.filter.pol,
                              "pod"
                            )
                          }} />}
                      />
                    </li>
                  );
                }
              }
            )}
          </Box>
        </FormControl>
        <FormControl variant="standard" style={{ width: "100%" }}>
          <StyledInputLable
            shrink
            style={{ fontWeight: 700 }}
            htmlFor="ETB DATE RANGE"
          >ETB DATE RANGE</StyledInputLable>
          <BootstrapInput
            defaultValue=""
            fullWidth
            id="ETB DATE RANGE"
            data-test-id="dateopne"
            onClick={this.TogolFilter}
            value={this.state.StartDate !== "" && this.state.EndDate !== "" ? `${this.state.RenderStart} - ${this.state.RenderEndDate}` : ""}
          />
          <CalendarImgBox>
            <img src={DateTimeIcon} alt="" onClick={this.TogolFilter} />
          </CalendarImgBox>
          <Box style={{
            position: "absolute",
            top: "70px",
            right: "0px"
          }}>
            {
              this.state.open &&
              <DateContainer style={{ position: "relative", zIndex: 9 }} data-test-id="DateFilterMainDiv">
                <DatePickerStyle
                  open={this.state.open}
                  toggle={this.TogolFilter}
                  wrapperClassName="DateMainClassName"
                  className="DateMainClassName"
                  onChange={(range: DateRange) => {
                    this.SetDateFilterData(range);
                  }}
                  initialDateRange={{ startDate: this.state.DateRangeStartDate || new Date(),
                    endDate: this.state.DateRangeEndDate || new Date(),
                  }}
                />
                <Box className="ButtonContaienr">
                  <Button style={{ marginRight: "10px" }} data-test-id="ClearFilter" variant="text" onClick={() => {
                    this.ClearFilterData()
                  }}>
                    <ProjectText variant="h6" TextSize="16px" textcolor="primary">
                      clear
                    </ProjectText>
                  </Button>
                  <Button variant="contained" data-test-id="SelectFilter" onClick={this.FilterDateSelect} color="primary" className="SelectButton">
                    <ProjectText variant="h6" TextSize="16px" textcolor="white">
                      Select
                    </ProjectText>
                  </Button>
                </Box>
              </DateContainer>
            }
          </Box>
        </FormControl>
      </CustomBox>
    )
  }

  spinnLoader = () => {
    if (this.state.loading) {
      return <Spinner spinnerModal={this.state.loading} />
    }
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <BuyerOffestyledrScreen>
        <Navbar SellerPendingScreen AppBarTransparent={false} TabDataCount={this.DynamicDataSet} />
        {this.spinnLoader()}
        <Container
          className="MainContainer"
          maxWidth="xl"
          data-test-id="SellerMatching"
          style={{ background: "#eee" }}
        >
          {this.FristGrid()}

          <StyledTabs
            onChange={this.handleTabChange}
            value={this.state.currentTab}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
            variant="fullWidth"
            data-test-id="tab"
            aria-label="full width tabs example"
          >
            
            <StyledTab
              disableTouchRipple
              label={`BUYER REQUESTS (${this.state.DynamicCount.requst})`}
              data-test-id="SellerPendingRequest"
              onClick={() =>
                this.props.navigation.navigate("SellerPendingRequest")
              }
            />
            <StyledTab
              disableTouchRipple
              label={`LISTINGS (${this.state.DynamicCount.listing})`}
              data-test-id="SellerListing"
              onClick={() => this.props.navigation.navigate("SellerListing")}
            />{" "}
            <NoBorderStyledTab
              disableTouchRipple
              label={`OFFERS (${this.state.DynamicCount.offers})`}
              data-test-id="SellerPendingOffers"
              onClick={() =>
                this.props.navigation.navigate("SellerPendingOffers")
              }
            />{" "}
            <NoBorderStyledTab disableTouchRipple label={`BOOKINGS (${this.state.DynamicCount.booking || 0})`} />
          </StyledTabs>
          {this.FilterContainerRedner()}
          {this.renderMaindiv()}

          {this.renderDialog()}
        </Container>

        {this.renderPopups()}
      </BuyerOffestyledrScreen>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const BuyerOffestyledrScreen = styled(Box)({
  root: {
    background: "#eee",
    padding: "30px 0",
    height: "100vh",
  },
  "& .MainContainer": {
    paddingTop: "30px",
  },
  "& .slotsFor": {
    margin: 0,
    fontFamily: `"Asap", "sans-serif"`,
    fontWeight: 800,
    fontSize: "28px",
    color: "#1E1E1E",
  },

  "& .slotsSection": {
    marginBottom: "50px",
    "& .leftBar": {
      display: "flex",
      alignItems: "center",
    },
  },

  "& .addRequest": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Asap",
    border: "none",
    fontSize: "14px",
    textTransform: "uppercase" as "uppercase",
    borderRadius: "32px",
    padding: "9px 18px",
    color: "#FFFFFF",
    marginRight: "30px",
    width: "256px",
    fontWeight: "bold",
    background: "#1653DD",
    marginLeft: "73%",
    height: "48px",
    cursor: "pointer",
  },

  "& .btnDiv": {
    display: "flex",
    alignItems: "center",
  },

  "& .btnImgDiv": {
    marginRight: "8px",
    width: "10%",
  },
  "& .selectSlotItem": {
    display: 'flex',
    width: '100%',
    height: '2.25rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '1rem'
  },
  "& .selectSlot": {
    width: "24.3125rem",
    height: "3rem",
    background: "rgba(255, 255, 255, 1)",
    border: ".0625rem solid rgba(205, 205, 205, 1)",
    marginLeft: ".9375rem",
    padding: '1rem .75rem',
    borderRadius: ".5rem",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  boxtextp:{
    marginLeft:"4px",
    height: "fit-content",fontSize: "13px",
    width: "fit-content",
    fontStyle: "normal",
    padding: "4px 16px 4px",background: "rgb(184, 213, 249)",
    fontWeight: 900,fontFamily: "Asap",
    whiteSpace: "nowrap" as "nowrap",
    borderRadius:"4px",
  },
  "& .selectSlotDropdown": {
    position: 'absolute' as 'absolute',
    width: '24.125rem', 
    background: 'white',
    borderRadius: '.5rem',
    boxShadow: "rgba(100, 100, 111, 0.2) 0rem .4375rem 1.8125rem 0rem",
    padding: '.25rem .375rem',
    display: 'flex',
    flexDirection: "column" as "column",
    gap: '.125rem',
    left: '.9375rem',
    marginTop: '.25rem',
    zIndex: 2,
  },
  "& .textEnd": {
    display: "flex",
    justifyContent: "end",
  },
  "& .DropDownDiv": {
    display: 'flex', 
    flexShrink: 0, 
    textTransform: 'uppercase', 
    gap: '.625rem', 
    alignItems: 'center', 
    justifyContent: 'center',
     paddingRight: '.625rem', 
     paddingLeft: '.625rem',
    "& .DropDownDivh3": {
      fontSize: '1rem',
      "@media (max-width: 600px)": {
        fontSize: '10px',
      },
    },
    "& .DropDownDivP": {
      fontSize: '1rem',
      "@media (max-width: 600px)": {
        fontSize: '10px',
      },
    },
    "@media (max-width: 600px)": {
      paddingLeft: '0px',
    },
  },
});

const StyledTabs = withStyles((theme) => ({
  root: {
    background: "#DADADA",
    color: " #363636",
    fontSize: "20px",
    borderTopLeftRadius: "5px",
    lineHeight: "normal",
    borderTopRightRadius: "5px",
    fontWeight: 500,
    fontFamily: "Asap, sans-serif",
    fontStyle: "normal",
  },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
  selected: {
    background: "#ffffff",
  },

  root: {
    borderTopRightRadius: "5px", 
    textTransform: "uppercase",
    color: "#1E1E1E", 
    overflow: "visible",
    fontStyle: "normal", 
    fontSize: "14px",
    fontWeight: "bold", 
    width: "451.5px",
    height: "72px", 
    borderTopLeftRadius: "5px",
    fontFamily: "Asap", 
    lineHeight: "normal",
  },

  wrapper: {
    borderRight: '1px solid rgba(172, 172, 172, 1)',
    padding: '14px 0px'
  }
}))(Tab);

const NoBorderStyledTab = withStyles((theme) => ({
  selected: {
    background: "#ffffff",
  },

  root: {
    borderTopRightRadius: "5px",
    textTransform: "uppercase",
    color: "#1E1E1E",
    overflow: "visible",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: "bold",
    width: "451.5px",
    height: "72px",
    borderTopLeftRadius: "5px",
    fontFamily: "Asap",
    lineHeight: "normal",
  },

  wrapper: {

  }
}))(Tab);

const StyledInputLable = withStyles((theme) => ({
  root: {
    fontWeight: 700,
    textTransform: "uppercase",
    fontSize: "18px",
    color: "#363636",
    fontFamily: "Asap",
  },
}))(InputLabel);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
    color: "#363636",
    border: "1px solid #ced4da",
    fontSize: "16px",
    position: "relative",
    fontFamily: "Asap",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderColor: "##DCDCD",
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  },
}))(InputBase);
const StyledSwitch = styled("div")({
  position: "relative",
  height: "2.125rem",
  display: "inline-block",
  width: "75px",
  marginRight: "15px",

  "& .slider": {
    position: "absolute",
    cursor: "pointer",
    bottom: 0,left: 0,
    right: 0,top: 0,
    transition: "background-color 0.4s",
    backgroundColor: "#ccc",

    "& input": {
      opacity: 0,width: 0,
      height: 0,
    },
    "&:before": {
      position: "absolute",
      bottom: ".25rem",
      width: "1.625rem",
      content: '""',
      transition: "transform 0.4s",
      height: "1.625rem",
      right: "0.56rem",
      backgroundColor: "white",
    },

    "& .text": {
      color: "white",
      right: "7px",
      top: "7px",
      position: "absolute",
      fontWeight: "bolder",
      fontFamily: "Asap",
      fontSize: "1rem"
    },
  },

  "& input:checked + .slider": {
    backgroundColor: "#1653DD",
  },

  "& input:checked + .slider:before": {
    transform: "translateX(-37px)",
  },

  "& input:focus + .slider": {
    boxShadow: "0 0 .0625rem #2196F3"
  },

  "& .slider.round": {
    borderRadius: "2.125rem",

    "&:before": {
      borderRadius: "50%"
    },
  },
});
const StyledDialogModal = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "100%", 
    maxWidth: "800px",
    padding: "25px 20px",
    borderRadius: "24px",
    paddingTop:'1%'
  },
  "& .textandPdfIcon": {
    display: "flex"
  },
  "& .PdfClass": {
    display: "flex", 
    justifyContent: "center"
  },
  "& .nominationClose": {
    textAlign: "right"
  },
  "& .SmallIcon": {
    cursor: "pointer",
    height: "1.375rem",
  },
  "& .TermsIcon": {
    cursor: "pointer",
    height: "1rem",
    marginLeft: "-15px",

  },
  "& .UploadFileBtn": {
    padding: "15px", 
    border: "1px solid #CDCDCD",
    borderRadius: "8px", 
    position: "relative",
    boder: "1px solid grey"
  },

  "& .nominateCheckbox": {
    "& .MuiSvgIcon-root": { fontSize: 28 },
  },

  "& .nominationCloseimg": {
    height: "24px"
  },

  "& .nominationMsggrid": {
    textAlign: "center"
  },

  "& .nominationErrorimg": {
    height: "100px"
  },

  "& .revisionText": {
    fontWeight: 400,
    fontSize: "18px",
    color: "#1E1E1E",
    fontFamily: "Asap",
  },
  "& .reasonBox": {
    margin: "5%",
    textAlign: "start",
    display: 'flex',
    flexDirection: 'column',
  },
  "& .reasonTextfield": {
    fontWeight:800,
    fontSize: "18px",
    color: "#1E1E1E",
    fontFamily: "Asap",
    width: "100%",
  },

  "& .refrence-input": {
    padding: 15,border: "1px solid #c4cBD4",
    width: "100%", marginTop: "10px",
    borderRadius: "5px"
  },

  "& .nominationHeading": {
    fontWeight: 700,fontSize: "28px",
    color: "#1E1E1E",fontFamily: "Asap",
  },

  "& .termsDiv": {
    display: 'flex',
    marginTop: -15
  },

  "& .refrence-inputTerms": {
    padding: 15, border: "1px solid #c4cBD4",
    width: "50%",marginTop: "-10px",
    borderRadius: "5px",fontSize:'16px'
  },

  "& .termsheading": {
    marginLeft: "25%",textAlign: "left",
    fontFamily: 'Asap', fontWeight: '600', fontSize: '16px'
  },

  "& .bookingRefrenceContent": {
    fontWeight: 700,
    fontSize: "14px",
    color: "#000",
    fontFamily: "Asap",
     float:'left',
     marginTop:'20px'
  },

  "& .nominationLinktext": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E1E1E",
    fontFamily: "Asap"
  },

  "& .nominationLink": {
    fontWeight: 500,
    fontSize: "14px",
    color: "#1653DD",
    fontFamily: "Asap"
  },
  "& .AuthorizeContent": {
    fontFamily: "Asap",
     marginTop: "25px"
  },
  "& .nominationButton": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "fit-content",
    padding: "10px 70px",
    backgroundColor: "#1653DD",
    color: "#fff",
    borderRadius: "25px",
    marginTop: "2%",
  },

  "& .nominationButton1": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "250px",
    backgroundColor: "#1653DD",
    color: "#fff",
    borderRadius: "25px",
    marginTop: "2%",
    margin: "4px"
  },

  "& .nominationButton2": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "250px",
    // padding: "10px 70px",
    backgroundColor: "#1653DD",
    color: "#fff",
    borderRadius: "25px",
    marginTop: "2%",
    margin: "4px"
  },

  "& .notYet": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "250px",
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: "25px",
    marginTop: "2%",
    margin: "4px",
    border: '2px solid #000'
  },
  "& .disbledBtn": {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Asap",
    height: "fit-content",
    width: "250px",
    backgroundColor: "gray",
    color: "#fff",
    borderRadius: "25px",
    marginTop: "2%"
  },

  "& .uploadButton": {
    width: "fit-content",
    padding: "10px 50px",
    textAlign: "start"
  },

  "& .verificationText": {
    fontWeight: "600",
    fontSize: "16px",
    color: "#363636",
    fontFamily: "Asap"
  },
  "& .uploadFiles": {
    width: "fit-content",
    padding: "10px 50px",
    textAlign: "start" ,
    color: "#000",
    fontWeight: "bold",
    fontFamily: "asap",
    textTransform: "none",
    marginTop: "12px"
  },
});

const MainDiv = styled(Box)({
  "@media (max-width: 1279px)": {
   overflow: "scroll"
  },
  "& .trackingDatabox": {
    margin: "1%"
  },
  "& .trackingDatacontainer": {
    textAlign: "center",
    "@media (max-width: 1279px)": {
      flexDirection: "column"
     },
  },
  "& .mainContainer": {
    display: "flex",fontSize: "16px",
    marginTop: "10px"
  },

  "& .box": {
    display: "flex",margin: "2%"
  },
  "& .boxPart1": {
    display: "flex",
    backgroundColor: "#C2E7FF",
    alignItems: "flex-start",
    flexDirection: "column",
    borderLeft: "6px solid #6AA2C6",
    borderRadius: "8px 0px 0px 8px",
    marginBottom: "1%",
    padding: "5px"
  },
  "& .boxText": {
    width: "fit-content",
    height: "fit-content",
    border: "1px solid #6AA2C6",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 900,
    fontFamily: "Asap",
    padding: "4px 20px 4px",
    background: "#C2E7FF",
    color: "#1653DD",
    whiteSpace: "nowrap",
    borderRadius:"4px"
  },
"& .firstSecondPart": {
    display: "flex",justifyContent:"center",
    alignItems: "center",padding: "20px 0px",
    gap: "16px",width: "95%",
    margin: "3%",
    "& .secondPart1": {
      width: "15%",
    }
  },
  "& .boxTextid": {
    color: "#1653DD",fontSize: "16px",
    fontWeight: 600,fontStyle: "normal",
    lineHeight: "14px",fontFamily: "Asap",
    textTransform: "uppercase",
    margin: "5%",
  },
  "& .first": {
    color: "#797979",fontSize: "12px",
    fontStyle: "italic",fontFamily: "Asap",
    fontWeight: 500,textTransform: "uppercase",
  },
  "& .secondBox": {
    marginRight: "5px"
  },
  "& .mbottom" :{
        marginBottom:"15px",
        display:"flex",
        flexDirection:"column",
        gap:"5px",
        alignItems:"flex-start"
  },
  "& .third": {
    color: "#1E1E1E",
    fontSize: "20px",
    fontFamily: "Asap",
    fontWeight: 500,
    lineHeight: "16px",
    gap: "16px",
    marginTop: "7px",
    whiteSpace: "nowrap",
    "@media (max-width: 768px)": {
      fontSize: '16px'
    },
  },
  "& .second": {
    color: "#1E1E1E",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "16px",
    fontFamily: "Asap",
    whiteSpace:"nowrap",
    "@media (max-width: 768px)": {
      fontSize: '16px'
    },
  },
  "& .middlepartoffirstbox": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    width: "60%",
  },
  "& .trainPart": {
    display: "flex", gap: "7px",
    color: " #1E1E1E",fontFamily: "Asap",
    fontSize: "20px",fontStyle: "normal",
    fontWeight: 500,lineHeight: "24px",
    width: "fitContent",whiteSpace:"nowrap",
    "@media (max-width: 768px)": {
      fontSize: '15px'
    },
  },
  "& .containerSize": {
    border: "1px solid #000", 
    padding: "1px 5px",
    background: "#F7F7F7", 
    borderRadius: "4px",
    margin: "0px 4px",
    fontSize: "14px",
    fontFamily: "Asap"
  },
  "& .trainImage": {
    height: "24px", 
    width: "24px"
   },
  "& .arrowImage": {
    color: "red",
    height: "20px"
  },
  "& .secondBoxMain": {
    backgroundColor: "#FFF",display: "flex",
    alignItems: "center", paddingTop: "5%",
    paddingBottom: "5%", gap: "25px",
    borderRadius: "0px 8px 8px 0px",width: "100%", 
    height: "96.5%",marginBottom: "1%",
    "& .editPop": {
      display: "flex", width: "121px",
      height: "36px",
      padding: " 9px 12px 9px 16px",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "8px",
      color: "#1E1E1E",
      fontFamily: "Asap",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "20px",
      textTransform: "uppercase",
    },
    "& .nominates_icon": {
      display: "flex",alignItems: "center",
      marginTop: "-24px",gap: "18px"
    }
  },
 "& .secondBoxPart1": {
    backgroundColor: "#D9D9D9",height: "58px",
    width: "58px",borderRadius: "31px",
    justifyContent: "center",alignItems: "center",
    display: "flex"
  },
  "& .AllImg": {
    width: "58px",height: "58px",
    borderRadius: "31px",background: "#D9D9D9"
  },
  "& .para": {
    color: "#797979",
    alignItems: "center",
    fontSize: "10px",
    fontWeight: "600"
  },
  "& .moreIcon": {
    marginLeft: "-15px"
  },
  "& .mainButtondisable": {
    backgroundColor: "gray",borderRadius: "62px",
    display: "flex",width: "175px",
    height: "44px",padding: "6px 10px",
    justifyContent: "center",alignItems: "center",
    gap: "6px",color: "white",
    textAlign: "center",fontFamily: "Asap",
    fontSize: "14px",fontStyle: "normal",
    fontWeight: 700,lineHeight: "16px",
    marginTop: "-7px",marginLeft: "-15px",
    textTransform: "uppercase",
  },
  "& .mainButton1": {
    backgroundColor: "#1653DD",borderRadius: "62px",
    display: "flex",width: "175px",
    height: "44px",padding: "6px 10px",
    marginLeft: "-17px",justifyContent: "center",
    alignItems: "center",gap: "6px",
    color: "var(--Basic-White, #FFF)",
    textAlign: "center",fontFamily: "Asap",
    fontSize: "14px",fontStyle: "normal",
    fontWeight: 700,lineHeight: "16px",
    marginTop: "-7px",textTransform: "uppercase"
  },
  "& .mainButtonDisabled":{
        backgroundColor: "#FF5C00",
        borderRadius: "62px",
        display: "flex",
        width: "175px",
        whiteSpace: "nowrap",
        height: "44px",
        padding: "6px 10px",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
        color: "var(--Basic-White, #FFF)",
        textAlign: "center",
        fontFamily: "Asap",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "16px",
        textTransform: "capitalize",
        opacity:"0.5"
      },
  "& .mainButtonconfirmed": {
    backgroundColor: "#A1EA88",
    border: "1px solid #168B1B",
    borderRadius: "62px",
    display: "flex",
    width: "100%", 
    height: "44px",
    padding: "6px 10px",
    justifyContent: "center",
    alignItems: "center",
    gap: "6px",
    color: "#168B1B",
    textAlign: "center",
    fontFamily: "Asap",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px",
    textTransform: "capitalize",
  },
  "& .ByerMatchesSubmittedBoxes": {
    padding: "10px 20px",
    "& .pTB50": {
      padding: "50px 0",
    },
    "& .BuyerHeading": {
      color: "#1E1E1E",
      fontSize: "28px",
      fontFamily: "Asap, sans-serif",
      fontWeight: 700
    },
    "& .mb20": {
      marginBottom: "20px"
    },
    "& .BuyerPara": {
      color: "#363636",
      fontSize: "16px",
      fontFamily: "Asap, sans-serif",
      margin: "0 0 5px 0"
    },
    "& .successBtn": {
      width: "30px",
      height: "30px"
    },
    "& .textEnd": {
      display: "flex",
      justifyContent: "end"
    },
    "& .RevisionModal": {
      color: "#363636",
      fontFamily: "Asap",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal"
    },
    "& .submitBtn": {
      display: "flex",alignItems: "center",
      justifyContent: "center",fontFamily: "Asap",
      fontSize: "16px", background: "#AA4649",
      border: "none",borderRadius: "32px",
      padding: "9px 18px",color: "#FFFFFF",
      fontWeight: 600,height: "56",
      width: "264",
      marginLeft: "28%",
      textTransform: "uppercase" as "uppercase",
      cursor: "pointer"
    },
    "& .dFlexJCenter": {
      display: "flex",alignItems: "center"
    },
  },
});

const DateContainer = styled(Box)({
  "& .ButtonContaienr": {
    position: "absolute",display: "flex",
    justifyContent: "flex-end",bottom: "2px",
    right: "10px",zIndex: 9,padding: ".625rem .375rem"
  },
  "& .DateMainClassName": {
    "& .MuiInputBase-input":{
      fontFamily: "Asap, sans-serif",
    },
    "& .MuiPaper-root": {
      paddingBottom: "20px",
      "& .MuiGrid-wrap-xs-nowrap": {
        "&.MuiGrid-root": {
          "& .materialui-daterange-picker-MuiList-root-59": {
            display: "none"
          }
        }
      }
    },
    "& .MuiTypography-root": {
      fontWeight: "500 !important",
      fontFamily: 'Asap !important'
    },
    "& .materialui-daterange-picker-makeStyles-highlighted-57": {
      color: "red !important",
    }
  },
  "& ul": {
    marginBottom: "20px"
  },
  "& li":{ fontFamily: "Asap, sans-serif"}
})

const DatePickerStyle = styled(DateRangePicker)({
  "& .MuiInputLabel-animated": {
    transition: "unset"
  },
  "& .DateMainClassName": {
    "& .MuiPaper-root": {
      paddingBottom: "60px !important",
      backgroundColor: "grey",
    }
  }
})

const CalendarImgBox = styled(Box)({
  root: {
    position: "relative"
  },
  "& img": {
    height: "24px", width: "24px", 
    position: "absolute", top: "32px", 
    zIndex: 1, right: "12px", cursor: "pointer"
  }
});
const DropdownArrowBox = styled(Box)({
  root: {
    position: "relative"
  },
  "& img": {
    height: "10px", 
    width: "10px", 
    position: "absolute", 
    top: "15px", 
    zIndex: 1, 
    right: "20px",
    cursor: "pointer"

  }
});
const CustomFormControlLabel = styled(FormControlLabel)({
  "& span": {
    fontFamily: "Asap"
  }
})

// Customizable Area End 