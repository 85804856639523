// Customizable Area Start
import React from "react";
import SellerListingController from "./SellerListingController";
import {
  Paper,
  Container,
  TableRow,
  Table,
  Checkbox,
  Grid,
  styled,
  FormControl,
  withStyles,
  Tab,
  Tabs,
  InputLabel,
  Box,
  InputBase,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Divider,
  Button,
  Snackbar, 
  SnackbarContent, 
  ClickAwayListener, 
  Grow, 
  Chip, 
  DialogContent, 
  FormControlLabel, 
  Collapse,
} from "@material-ui/core";
import moment from "moment";
import Popper from '@material-ui/core/Popper';
import {
  resultNotFound1,
  ellipsis,
  image_dropdown,
  redQue,
  DateTimeIcon
} from "./assets";
import ProjectText from "../../../components/src/ProjectText";
import { 
  ContainedButton
 } from "../../../components/src/GlobalButton";
import { Theme } from "@material-ui/core/styles";
import { 
  withRouter
 } from "react-router-dom";
import Navbar from "../../../components/src/NavBar.web";
import Spinner from "../../../components/src/ProjectLoader";
import CancelIcon from '@material-ui/icons/Cancel';
import ProjectModel from "../../../components/src/ProjectModel";
import { 
   DateRangePicker,
   DateRange
   } from "materialui-daterange-picker";
import AddListing from "../../../components/src/AddListing";
import { 
  BorderedStyledTab
 } from "./SellerPendingRequest.web";

const StyledTabs = withStyles((theme) => ({
  root: {
    borderTopRightRadius: ".3125rem",
    background: "#DADADA", 
    color: " #363636",
    fontFamily: "Asap, sans-serif",
    fontSize: "1.25rem",
    borderTopLeftRadius: ".3125rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal"
  },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    borderTopRightRadius: ".3125rem",
    lineHeight: "normal",
    borderTopLeftRadius: ".3125rem",
    fontWeight: "bold",
    overflow: "visible",
    color: "#1E1E1E", 
    fontStyle: "normal",
    fontSize: ".875rem",
    fontFamily: "Asap",
    textTransform: "uppercase",
    width: "28.2188rem",
    height: "4.5rem",
  },
  selected: {
    background: "#ffffff"
  },

  wrapper: {
    padding: '14px 0px'
  }
}))(Tab);

const NoBorderStyledTab = withStyles((theme) => ({
  root: {
    borderTopRightRadius: ".3125rem",
    fontStyle: "normal",
    lineHeight: "normal",
    borderTopLeftRadius: ".3125rem",
    fontWeight: "bold",
    overflow: "visible",
    color: "#1E1E1E",
    fontFamily: "Asap", 
    fontSize: ".875rem",
    textTransform: "uppercase",
    width: "28.2188rem",
    height: "4.5rem",
  },
  selected: {
    background: "#ffffff"
  }
}))(Tab);

const StyledInputLable = withStyles((theme) => ({
  root: {
    fontSize: "1.125rem",
    fontWeight: 700,
    color: "#363636",
    textTransform: "uppercase",
    fontFamily: "Asap"
  },
}))(InputLabel);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    },
  },
  input: {
    borderRadius: 5, 
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: ".0625rem solid #ced4da",
    padding: ".625rem .75rem",
    fontSize: "1rem",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    color: "#363636",
    fontFamily: "Asap",
    "&:focus": {
      borderColor: "##DCDCD",
      boxShadow: "rgba(149, 157, 165, 0.2) 0rem .5rem 1.5rem",
    },
  },
}))(InputBase);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#1E1E1E", 
    fontWeight: 600,
    verticalAlign: "baseline",
    fontFamily: "Asap", 
    fontSize: ".875rem",
    fontStyle: "normal", 
    lineHeight: "1.5rem",
    textTransform: "unset", 
    background: "#E1E5EE",
    textAlign:"center",
    // borderRadius: ".3125rem 0rem 0rem .3125rem",
    borderBottom: ".125rem solid #8A99BB",
    "@media only screen and (min-device-width: 20rem) and (max-device-width: 47.9375rem)": {
      "&:nth-child(8) > ul": {
        width: "25rem"
      }
    },
    "@media only screen and (min-device-width: 48rem) and (max-device-width: 74.9375rem)": {
      "&:nth-child(8) > ul": {
        width: "31.25rem"
      }
    },
    "@media only screen and (min-device-width: 75rem) and (max-device-width: 118.75rem)": {
      "&:nth-child(7) > ul": {
        // width: "25.25rem"
      }
    }
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    borderRadius: ".625rem",
    color: "#363636",
    fontFamily: "Asap",
    fontSize: ".875rem",
    fontStyle: "normal",
    fontWeight: 400,
    textAlign:"center",
    lineHeight: "1rem",
    textTransform: "unset",
    background: "#fff",
  },
}))(TableRow);

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel"
      hidden={value !== index} 
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}  >
      {value === index && children}
    </div>
  );
};

const StyledTable = withStyles((theme) => ({
  root: {
    border: "none",
    borderCollapse: "separate",
     borderSpacing: "0 .1875rem", 
     rowGap: ".625rem", 
     background: "transparent",
  },
}))(Table);

const StyledTableHeaderRow = withStyles((theme) => ({
  root: {
    // backgroundColor: "#e1e5ee",
  },
}))(TableRow);

const columns = [
  { id: "org_", label: ["ORG "], 
  minWidth: "auto", 
  alignment: 'start' },
  { id: "request_id",label: ["LISTING ID"],
   minWidth: "auto", 
   alignment: 'start' },
  { id: "date_time", label: ["ETB"], 
  minWidth: "90px", 
  maxWidth: "90px", 
  alignment: 'start' },
  {
    id: "pol_terminal", label: ["VES/VOY"], 
    minWidth: "100px", 
    maxWidth: "100px",  
    alignment: 'start'
  },
  { id: "pod_terminal", label: ["POL"], 
  minWidth: "auto", 
  alignment: 'center' 
},
  {
    id: "type_", label: ["POD"], 
    minWidth: "auto", 
    alignment: 'center'
  },
  { 
    id: "code_", label: ["ETA"], 
    minWidth: "90px", 
    maxWidth: "90px", 
  alignment: 'center' 
},

  {
    id: "quantity", label: ["STATUS", "TYPES", "Seller Price (USD)"], 
    minWidth: "270px", 
    alignment: 'center'
  },
  {
    id: "total",label: ["Terms"], 
    minWidth: "60px", 
  maxWidth: "60px", 
    alignment: 'center'
  },
  {
    id: "quan", label: ["Quantity (TEU)"], 
    minWidth: "auto",
     alignment: 'start'
  },
];

const StyledSwitch = styled("div")({
  height: "34px",
  position: "relative",
  marginRight: ".9375rem",
  display: "inline-block",
  width: "4.6875rem",
  "& input": {
    height: 0,
    opacity: 0,
    width: 0,
  },

  "& .slider": {
    right: 0,bottom: 0,
    position: "absolute",
    cursor: "pointer",
    top: 0,left: 0,
    backgroundColor: "#ccc",
    transition: "background-color 0.4s",
    "& .text": {
      top: "4.8px",
      position: "absolute",
      fontSize: "16px",
      left: "10px",
      color: "white",
      fontWeight: "bolder",
    },
    "&:before": {
      width: "26px",
      position: "absolute",
      content: '""',
      backgroundColor: "white",
      height: "26px",
      left: "8.96px",
      bottom: "4px",
      transition: "transform 0.4s",
    },
  },

  "& input:checked + .slider": {
    backgroundColor: "#1653DD",
  },

  "& input:focus + .slider": {
    boxShadow: "0 0 1px #2196F3",
  },

  "& input:checked + .slider:before": {
    transform: "translateX(2.3375rem)",
  },

  "& .slider.round": {
    borderRadius: "34px",

    "&:before": {
      borderRadius: "50%",
    },
  },
});
export const SectionWrapperLeft = styled("section")({
  "& .selectSlotInput": {
    "@media (max-width: 422px)": {
      marginLeft: '0px !important'
    },
    "@media (max-width: 960px)": {
      minWidth: "12rem",
      width: "100% !important"
    },
    "@media (max-width: 430px)": {
      minWidth: "13rem",
      width: "100% !important"
    },
  },
  "& .selectSlotDropdown": {
    "@media (max-width: 960px)": {
      width: "20.125rem !important",
      left: "-99px !important"
    },
  },
  "& .DropDownDiv": {
    display: 'flex',
    flexShrink: 0, 
    textTransform: 'uppercase',
    gap: '.625rem', 
    alignItems: 'center', 
    justifyContent: 'center', 
    paddingRight: '.625rem',
    paddingLeft: '.625rem',
    "& .DropDownDivh3": {
      fontSize: '1rem',
      "@media (max-width: 600px)": {
        fontSize: '10px',
      },
    },
    "& .DropDownDivP": {
      fontSize: '1rem',
      "@media (max-width: 600px)": {
        fontSize: '10px',
      },
    },
    "@media (max-width: 600px)": {
      paddingLeft: '0px',
    },
  },
})

class SellerListing extends SellerListingController {

   getPrincipalOrg(principalOrgList: any): string | JSX.Element{
    if (Array.isArray(principalOrgList)) {
      return "ALL";
    } else if (principalOrgList.type === "organization") {
      return (
        <>
        <span>{principalOrgList.attributes?.name}
        </span>
        <p  style={webStyle.boxtextp}>
            {principalOrgList.attributes?.code} </p>
    </>
      )
    }
    return "ALL";
  }
  
  MapData = () => {
    // Extracting state variables
    let text = "";
    const activeSlotFilter = this.state.activeSlotFilter;
    let backgroundColor = activeSlotFilter[0]?.attributes?.color_name;
  
    // Building the text and backgroundColor strings
    activeSlotFilter.forEach((slot: any) => {
      text += `${slot.attributes?.name?.trim().split(" ").join("/")} ${slot.attributes?.code} `;
      if (slot.attributes?.color_name)  {
        backgroundColor += ` ${slot.attributes?.color_name} `; }
    }
    );    
    // Handling empty state case
    if (activeSlotFilter.length === 0) {
      return this.getPrincipalOrg(this.state.prinicpalOrgList);
    }
  
    // Truncating text if necessary
    const truncatedText = text.length > 28 
    ? text.slice(0, 28) + " ..." 
    : text;
  
    return (
      <div  className="DropDownDiv" 
      title={text?.split("/").join(" ")}>
        {truncatedText.split(" ").filter(txt => txt !== "").map((txt, index) => {
          if (index % 2 === 0) {
            return (
              <h3 
              className="DropDownDivh3" 
              key={index}>      {txt?.split("/").join(" ")} 
              </h3>
            );
          } else {
            return (
              <p
                className="DropDownDivP" 
                key={index}
                style={{padding: ".25rem .625rem",
                  borderRadius: ".25rem",  backgroundColor: backgroundColor?.split(" ").filter((txt:any) => txt !== "")[index],
                }}>
                {txt}
              </p>  );    }
        }
        )
        }
      </div> );
  };
  FristGrid = () => {
    this.MapData()

    const MapDataElse = () => {
      return (
        <div className="DropDownDiv">
          <h3 className="DropDownDivh3">
            {this.state.activeSlotFilter?.attributes?.name}
            </h3>
          <p className="DropDownDivP" 
          style={{ padding: '.25rem .625rem', 
          borderRadius: '.25rem', backgroundColor: this.state.activeSlotFilter?.attributes?.color_name }}>{this.state.activeSlotFilter?.attributes?.code}</p>
        </div>)
    }

    return (
      <Grid container alignItems="center" 
      style={webStyle.slotsSection}>
        <Grid item lg={6} md={6}>
          <SectionWrapperLeft style={webStyle.leftBar}>
            <StyledSwitch>
              <div className="switch" onClick={() => this.SwitchChnages()} data-test-id="switch-changes">
                <input className="Inputswitch"
                  type="checkbox"  checked={this.state.switch}
                />
                <span className="slider round"> <ProjectText TextSize="1rem" fontweight="700" className="text">
                    {this.state.switch ? "SELL" : ""}
                  </ProjectText>  </span>
              </div>
            </StyledSwitch>
            <h1 style={webStyle.slotsFor}>
              Slots for</h1>
            <div 
            style={{ position: 'relative', color: 'rgba(30, 30, 30, 1)', fontFamily: 'Asap', fontSize: '.875rem', fontWeight: 500 }}>
              <div className="selectSlotInput" data-test-id="organizationFilter" onClick={() => this.setState({ isSlotFilterOpen: !this.state.isSlotFilterOpen })}
                style={webStyle.selectSlot}>
                <div style={{ fontWeight: 500, display: 'flex', overflow: 'auto' ,alignItems:"center" }}>
                  {Array.isArray(this.state.activeSlotFilter) ?  this.MapData() :  <MapDataElse />}
                </div>
                <img src={image_dropdown} alt="dropdown" style={{width: '10px', height: '10px', objectFit: 'contain'}} />
              </div>
              {this.state.isSlotFilterOpen && (
                <ClickAwayListener 
                onClickAway={() => this.setState({ isSlotFilterOpen: false })}>
                  <Box>
                  
                    {Array.isArray(this.state.prinicpalOrgList) ? (
                    <div data-test-id="organizationFilterDropdown" 
                    className="selectSlotDropdown"
                     style={webStyle.selectSlotDropdown}>
                     {this.state.prinicpalOrgList?.map((val: any) => {
                      return (
                        <div key={val.id}
                          style={{ borderLeft: `.25rem solid ${val.attributes.color_name}`, ...webStyle.selectSlotItem }}>
                          <h4 style={{ textTransform: 'uppercase' }}>{val.attributes.name}
                          </h4>
                          <Checkbox color="primary"
                            checked={this.state.activeSlotFilter.includes(val)}
                            data-test-id="organizationFilterDropdownCheck"
                            onChange={(e) => this.handleSlotFilter(val, e.target.checked)}
                          />
                        </div>
                     )})}
                       </div>)
                    : this.state.prinicpalOrgList.type !== "organization" && (
                      <div data-test-id="organizationFilterDropdown" 
                    className="selectSlotDropdown"
                     style={webStyle.selectSlotDropdown}>
                    <div  
                      key={this.state.prinicpalOrgList.id}
                          style={{ borderLeft: `.25rem solid ${this.state.prinicpalOrgList.attributes.color_name}`, 
                          ...webStyle.selectSlotItem }}>
                          <h4 style={{ textTransform: 'uppercase' }}
                          > {this.state.prinicpalOrgList.attributes.name}
                          </h4>
                          <Checkbox color="primary" 
                          checked=
                          {this.state.activeSlotFilter.includes(this.state.prinicpalOrgList)}
                          onChange={(e) => this.handleSlotFilter(this.state.prinicpalOrgList, e.target.checked)}/>
                        </div>
                        </div>
                    )               
                    }
                    </Box>
                </ClickAwayListener>
              )}
            </div>
          </SectionWrapperLeft>
        </Grid>
        <Grid item xs={12} lg={6} md={6} style={webStyle.textEnd}>

          <AddListing EditId={this.state.EditFormId} APicallForTable={() => this.getSellerListingData()} />

        </Grid>
      </Grid>
    )
  }

  TableBodyLastCell = (list: { seller_listing: { data: { id: string, attributes: { available_slot: string, total: string, booked_slot: string, total_teu: string, listing_uid: string } } } }) => {
    return (
      <TableCell style={webStyle.textCenter}>
        <section style={{ ...webStyle.btnDiv }}>
          <div style={webStyle.quantityDiv}>
            <div style={webStyle.mr15}>
              <span style={webStyle.available}>
                Available
              </span>
              <p style={webStyle.availableQuantity}>{
                list?.seller_listing?.data?.attributes?.available_slot}</p>
            </div>
            <div>
              <span style={webStyle.total}>Total {parseInt(list?.seller_listing?.data?.attributes?.available_slot) + parseInt(list?.seller_listing.data?.attributes?.booked_slot)}</span>
              <div style={webStyle.bookedAmount}>
                Booked {list?.seller_listing.data?.attributes?.booked_slot}
              </div>
            </div>
            {this.state.showEditDeletePopUp && this.state.DeleteTablePopUpIndex === Number(list?.seller_listing?.data?.id) &&
              <div style={{ position: "relative" }}>
                <Popper className="MenuContainer" anchorEl={this.state.anchorEl} open={this.state.showEditDeletePopUp} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'left bottom' : 'center bottom' }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={(e) => this.HandelCloseDeleteAndEdit(e, -1)}>
                          <Paper style={webStyle.uncontrolledBox} className="EditAndDeletePopUp">
                            <h6 data-test-id={`EditModelOpen${list?.seller_listing?.data?.id}`} onClick={() => this.EditFuncationOnclick(list?.seller_listing?.data?.id)} style={webStyle.editText}>EDIT</h6>
                            <h6 data-test-id={`DelteData${list?.seller_listing?.data?.id}`} onClick={() => this.DeleteModelOpenClose(list?.seller_listing?.data?.id, list.seller_listing.data.attributes.listing_uid)} style={webStyle.deleteText}>DELETE</h6>
                          </Paper>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            }
          </div>
          <div onClick={(e) => this.HandelCloseDeleteAndEdit(e, Number(list?.seller_listing?.data?.id))} data-test-id={`EditDiv${list?.seller_listing?.data?.id}`}>
            <img src={ellipsis} alt="" className="ThirDots" />
          </div>
        </section>
      </TableCell>
    )
  }

  DeleteModelRender = () => {
    return (
      <ProjectModel IconButtonBgColor="black" CloseIconSize="16px" BorderRadius="1.25rem" BtnColor="white" maxWidth="md" ModelTitleColor="white" TitlePadding=".3125rem" onClose={() => this.DeleteModelOpenClose("", "")} DialogContentPadding={true} fullWidth modalTitle="" aria-labelledby="customized-dialog-title" OpenModel={this.state.DeleteModel}>
        <DialogContent>
          <LogoutModel>
            <Box className="LogoutModelContainer">
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <Box className="QuetionMarkImgClass">
                    <img src={redQue} alt="QuetionMarkImg" />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <ProjectText TextSize="1.75rem" fontweight="700" align="center">
                    Delete Listing?
                  </ProjectText>
                </Grid>
                <Grid item xs={12}>
                  <ProjectText TextSize="16px" fontweight="500" align="center" style={{fontFamily:"Asap"}}>
                    {`Are you sure you want to delete this listing (ID:${this.state.DeleteListingUid}) ? This action cannot be undone.`}
                  </ProjectText>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end">
                  <ContainedButton onClick={() => this.DeleteSellerListing(this.state.DeleteId)} HoverColor="#DB2525" MarginFalse fullWidth bgcolor="#DB2525" data-test-id="DeleteButton">
                    DELETE
                  </ContainedButton>
                </Grid>
                <Grid item xs={4}>
                  <ContainedButton HoverColor="white"
                    TextColor="black" onClick={() => this.DeleteModelOpenClose("", "")} BorderColor="black" MarginFalse fullWidth bgcolor="white">
                    CANCEL
                  </ContainedButton>
                </Grid>
              </Grid>
            </Box>
          </LogoutModel>
        </DialogContent>
      </ProjectModel>
    )
  }
  DeleteErrorModelRender = () => {
    return (
      <ProjectModel 
        IconButtonBgColor="black" 
        CloseIconSize="16px" 
        BorderRadius="1.25rem" 
        BtnColor="white" 
        maxWidth="md" 
        ModelTitleColor="white" 
        TitlePadding=".3125rem" 
        onClose={() => this.DeleteErrorModelOpenClose()} 
        DialogContentPadding={true} 
        fullWidth 
        modalTitle="" 
        aria-labelledby="customized-dialog-title" 
        OpenModel={this.state.DeleteErrorModel}>
        <DialogContent>
          <LogoutModel>
            <Box className="LogoutModelContainer">
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                  <Box className="QuetionMarkImgClass">
                    <img src={resultNotFound1} alt="Warning" style={{width: 150, height: 150, objectFit: 'contain'}} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <ProjectText TextSize="16px" fontweight="400" align="center">
                  This listing can not be deleted, as there is a booking under process for this.
                  </ProjectText>
                </Grid>
                <Grid item xs={4}>
                  <ContainedButton 
                    HoverColor="#1653DD"
                    TextColor="white" 
                    onClick={() => this.DeleteErrorModelOpenClose()} 
                    BorderColor="black" 
                    MarginFalse 
                    fullWidth 
                    bgcolor="#1653DD">
                    OK
                  </ContainedButton>
                </Grid>
              </Grid>
            </Box>
          </LogoutModel>
        </DialogContent>
      </ProjectModel>
    )
  }

  renderListingFilter = () => {
    return(
      <>
      <FormControl style={{ width: "100%", marginRight: ".9375rem" }}>
          <StyledInputLable shrink>LISTING ID</StyledInputLable>
          <Box
            style={{ marginTop: "1.5rem" }}>
            <StyledBox
              data-test-id="listingfilter" onClick={() => this.handleOfferExpanded("listingID")}
            >
              <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.offerExpanded ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleOfferExpanded("listingID")} />
                  </DropdownArrowBox>
            </StyledBox>
            {this.state.offerExpanded && (
              <ClickAwayListener
                onClickAway={() => this.setState({ offerExpanded: false })}
              >
                <Collapse
                  in={this.state.offerExpanded}
                  disableStrictModeCompat={false}
                  timeout="auto"
                  unmountOnExit
                  style={{
                    display: "flex",
                    position: "absolute",
                    width: "100%",
                    background: "#fff",
                    zIndex: 1,
                    flexDirection: "column", height: "130px", overflowY: "auto", overflowX: "hidden"
                  }}
                >
                  {this.state.filter?.listingID?.map(
                    (item: { label: string; isChecked: boolean }, index: number) => {
                      return (
                        <CustomFormControlLabel
                          key={item.label}
                          control={
                            <Checkbox
                              data-test-id={`listingID${index}`}
                              color="primary"
                              checked={item.isChecked}
                              onClick={(e) => this.handleOfferChange(e, this.state.filter.listingID, "listingID")} style={{ paddingRight: "25px" }} name={item.label} />}
                          labelPlacement="start" label={item.label}
                          style={{
                            display: "flex", justifyContent: "space-between", flexDirection: "row-reverse",
                            fontFamily: "Asap", fontSize: ".875rem",
                          }}
                        />
                      );
                    }
                  )}
                </Collapse>
              </ClickAwayListener>
            )}
          </Box>
          <Box
            style={{
              padding: ".125rem",
              width: "13.75rem",
              display: "flex", justifyContent: "start", flexWrap: "wrap", gap: ".3125rem", marginTop: ".1875rem",
              listStyle: "none",
            }}
          >
            {this.state.filter.listingID.map(
              (option: { label: string; isChecked: boolean }) => {
                if (option.isChecked) {
                  return (
                    <li
                      style={{ fontFamily: "Asap", fontSize: ".875rem" }}
                      key={option.label}
                    >
                      <StyledChip
                        icon={<CancelIcon data-test-id={`CancelIcon`} onClick={() => {
                          this.handleOfferDelete(
                            option,
                            this.state.filter.listingID,
                            "listingID"
                          )
                        }} />}
                        data-test-id="listingIdChip"
                        label={option.label}
                      />
                    </li>
                  );
                }
              }
            )}
          </Box>
        </FormControl>
        <FormControl style={{ width: "100%", marginRight: ".9375rem" }}>
          <Box style={{ marginTop: "1.5rem" }}>
            <StyledBox data-test-id="typefilter" onClick={() => this.handleOfferExpanded("vessel")} >
            <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.offerExpandedtype ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleOfferExpanded("vessel")} />
                  </DropdownArrowBox>
            </StyledBox>
            {this.state.offerExpandedtype && (
              <ClickAwayListener
                onClickAway={() => this.setState({ offerExpandedtype: false })}
              >
                <Collapse
                  in={this.state.offerExpandedtype}
                  timeout="auto"
                  unmountOnExit
                  disableStrictModeCompat={false}
                  style={{
                    zIndex: 1,
                    width: "100%",
                    background: "#fff",
                    display: "flex",
                    position: "absolute",
                    flexDirection: "column", height: "130px", overflowY: "auto", overflowX: "hidden"
                  }}
                >
                  {this.state.filter?.vessel?.map(
                    (item: { label: string; isChecked: boolean }, index: number) => {
                      return (
                        <CustomFormControlLabel
                          key={item.label}
                          control={
                            <Checkbox
                              checked={item.isChecked}
                              color="primary"
                              data-test-id={`typefilterVESSEL/VOY${index}`}
                              onClick={(e) =>
                                this.handleOfferChange(
                                  e,
                                  this.state.filter.vessel,
                                  "vessel"
                                )
                              }
                              style={{ paddingRight: "1.875rem" }}
                              name={item.label}
                            />
                          }
                          label={item.label}
                          labelPlacement="start"
                          style={{
                            justifyContent: "space-between",
                            fontSize: ".875rem",
                            display: "flex",
                            flexDirection: "row-reverse",
                            fontFamily: "Asap",
                          }}
                        />
                      );
                    }
                  )}
                </Collapse>
              </ClickAwayListener>
            )} <StyledInputLable shrink>VESSEL/VOY</StyledInputLable>{" "}
          </Box>
          <Box  style={{
              display: "flex",flexWrap: "wrap",
              marginTop: ".1875rem", width: "13.75rem",
              justifyContent: "start", gap: ".3125rem",
              listStyle: "none",padding: ".125rem",
            }}
          >
            {this.state.filter.vessel.map((option: { label: string; isChecked: boolean }, index: number) => {
                if (option.isChecked) 
                {
                  return (
                    <li style={{ fontFamily: "Asap", fontSize: ".875rem" }}
                      key={option.label} >
                      <StyledChip  icon={<CancelIcon data-test-id={`deltevessel${index}`} onClick={() => {
                          this.handleOfferDelete(option,
                            this.state.filter.vessel,
                            "vessel"
                          )
                        }} 
                        />
                      }label={option.label} />
                    </li> );
                }}
            )}
          </Box>   
          </FormControl>
      </>
    )
  }

  FilterContainerRedner = () => {
    return (
      <CustomBox>
        {this.renderListingFilter()}
        <FormControl 
        style={{ width: "100%",
         marginRight: ".9375rem" }}>
          <StyledInputLable shrink>POL
          </StyledInputLable>
          <Box 
          style={{ marginTop: "1.5rem" }}>
            <StyledBox data-test-id="polfilter" onClick={() => this.handleOfferExpanded("pol")}>
            <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.offerExpandedPol ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleOfferExpanded("pol")} />
                  </DropdownArrowBox>
            </StyledBox>
            {this.state.offerExpandedPol && (
              <ClickAwayListener
                onClickAway={() => this.setState({ offerExpandedPol: false })} >
                <Collapse
                  in={this.state.offerExpandedPol}
                  timeout="auto" unmountOnExit
                  disableStrictModeCompat={false}
                  style={{
                    zIndex: 1, width: "100%", background: "#fff", display: "flex",
                    position: "absolute",
                    flexDirection: "column", height: "130px", overflowY: "auto", overflowX: "hidden"
                  }}
                >
                  {this.state.filter?.pol?.map(
                    (item: {
                      label: string;
                      isChecked: boolean
                    }, index: number) => {
                      return (
                        <CustomFormControlLabel
                          key={item.label}
                          control={
                            <Checkbox
                              data-test-id={"polcheck" + index}
                              color="primary"
                              checked={item.isChecked}
                              onClick={(e) =>
                                this.handleOfferChange(
                                  e,
                                  this.state.filter.pol,
                                  "pol"
                                )
                              }
                              name={item.label}
                              style={{ paddingRight: "1.875rem" }}
                            />
                          }label={item.label}
                          style={{ fontFamily: "Asap",
                            display: "flex",flexDirection: "row-reverse",
                            justifyContent: "space-between",fontSize: ".875rem",
                          }}
                          labelPlacement="start" />
                      );}
                  )}
                </Collapse>
              </ClickAwayListener> )}
          </Box>
          <Box style={{gap: ".3125rem", marginTop: ".1875rem",
              width: "13.75rem", display: "flex",
              justifyContent: "start",flexWrap: "wrap",
              listStyle: "none", padding: ".125rem",
            }}  >
            {this.state.filter.pol.map(
              (option: {label: string; isChecked: boolean
              }, index: number) => {
                if (option.isChecked) {
                  return (
                    <li key={option.label}
                      style={{fontFamily: "Asap", fontSize: ".875rem" }} >
                      <StyledChip
                        icon={<CancelIcon data-test-id={`POL${index}`} onClick={() => {
                          this.handleOfferDelete(
                            option,
                            this.state.filter.pol,
                            "pol" )}} />
                          } label={option.label}/>
                    </li>
                  ); }
              } )}
          </Box>
        </FormControl>
        <FormControl style={{ width: "100%",marginRight: ".9375rem"
        }}>
          <StyledInputLable shrink>  POD
          </StyledInputLable>
          <Box 
          style={{ marginTop: "1.5rem" }}>
            <StyledBox data-test-id="podfilter"  onClick={() => this.handleOfferExpanded("pod")}>
            <DropdownArrowBox> 
                    <img src={image_dropdown} style={this.state.offerExpandedPod ? {transform: "scaleY(-1)"}: {}} alt="" onClick={() => this.handleOfferExpanded("pod")} />
                  </DropdownArrowBox>
            </StyledBox>
            {this.state.offerExpandedPod && (
              <ClickAwayListener onClickAway={() => this.setState({ offerExpandedPod: false })}
              >
                <Collapse  disableStrictModeCompat={false}
                  in={this.state.offerExpandedPod}
                  timeout="auto" unmountOnExit
                  style={{ background: "#fff",
                    position: "absolute",  zIndex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column", height: "130px", overflowY: "auto", overflowX: "hidden"  }}
                >
                  {this.state.filter?.pod?.map(
                    (item: {
                      label: string;
                      isChecked: boolean
                    },
                      index: number) => {
                      return (
                        <CustomFormControlLabel
                          key={item.label}
                          control={
                            <Checkbox
                              data-test-id={"podcheck" + index}
                              color="primary"
                              checked={item.isChecked}
                              onClick={(e) =>
                                this.handleOfferChange(
                                  e,
                                  this.state.filter.pod,
                                  "pod"
                                )
                              }
                              style={{ paddingRight: "1.875rem" }}
                              name={item.label}
                            />
                          }
                          labelPlacement="start" label={item.label}
                          style={{ display: "flex",
                            justifyContent: "space-between",
                            fontFamily: "Asap",fontSize: ".875rem",
                            flexDirection: "row-reverse",
                          }}  />
                      ); }
                  )}
                </Collapse>
              </ClickAwayListener>

            )}
          </Box>
          <Box style={{ gap: ".3125rem",
              width: "13.75rem", padding: ".125rem",
              display: "flex",justifyContent: "start",
              flexWrap: "wrap",listStyle: "none",
              marginTop: ".1875rem",
            }}
          >
            {this.state.filter.pod.map((option: { label: string; isChecked: boolean }, index: number) => {
                if (option.isChecked) {
                  return (
                    <li style={{ fontFamily: "Asap", 
                    fontSize: ".875rem" }}
                      key={option.label}
                    >
                      <StyledChip
                        label={option.label} icon={<CancelIcon
                          data-test-id={`PodDelete${index}`}
                          onClick={() => { this.handleOfferDelete(
                              option,
                              this.state.filter.pol,
                              "pod"
                            )
                          }} />}
                      /> </li>
                  ); } }
            )}
          </Box>
        </FormControl>
        <FormControl variant="standard" 
        style={{ width: "100%" }}>
          <StyledInputLable shrink
            style={{ fontWeight: 700 }}htmlFor="ETB DATE RANGE"
          >ETB DATE RANGE</StyledInputLable>
          <BootstrapInput
            defaultValue=""  fullWidth
            id="ETB DATE RANGE"   data-test-id="dateopne"
            onClick={this.TogolFilter}
            value={this.state.StartDate !== "" 
            && this.state.EndDate !== "" ? `${this.state.RenderStart} - ${this.state.RenderEndDate}` : ""}
          />
          <CalendarImgBox><img src={DateTimeIcon} alt="" onClick={this.TogolFilter} /></CalendarImgBox>
          <Box style={{ position: "absolute", top: "70px", right: "0px" }}>
            {this.state.open &&
              <DateContainer style={{ position: "relative" }} data-test-id="DateFilterMainDiv">
                <DatePickerStyle
                  toggle={this.TogolFilter}
                  open={this.state.open}
                  className="DateMainClassName"
                  onChange={(range: DateRange) => {
                    this.SetDateFilterData(range);
                  }}
                  initialDateRange={{
                    startDate: this.state.DateRangeStartDate,
                    endDate: this.state.DateRangeEndDate
                  }}
                  wrapperClassName="DateMainClassName"
                />
                <Box className="ButtonContaienr">
                  <Button style={{ marginRight: "10px" }}
                    data-test-id="ClearFilter"
                    variant="text"
                    onClick={() => {
                      this.ClearFilterData()
                    }}>
                    <ProjectText variant="h6" TextSize="16px" textcolor="primary">clear</ProjectText>
                  </Button>
                  <Button variant="contained" data-test-id="SelectFilter" onClick={this.FilterDateSelect} color="primary" className="SelectButton">
                    <ProjectText variant="h6" TextSize="16px" textcolor="white">Select</ProjectText>
                  </Button>
                </Box>
              </DateContainer>
            }
          </Box>
        </FormControl>
      </CustomBox>
    )
  }
  
  getRenderedItem(data: any, index: number, currentIndex: number) {
    const currentItem = data.container_details[currentIndex]?.terms?.toUpperCase();
    const nextItem = data.container_details[currentIndex + 1]?.terms?.toUpperCase();
    const previousItem = data.container_details[currentIndex - 1]?.terms?.toUpperCase();

    if (data.container_details.length <= 1) {
        return currentItem;
    }

    if (data.container_details[currentIndex]?.container_size === "20 FT") {
        if (data.container_details.length >= currentIndex + 1 && currentIndex % 2 === 0) {
            return nextItem;
        } else {
            return currentItem;
        }
    } else {
        if (currentIndex === 0) {
            return currentItem;
        } else {
            return previousItem;
        }
    }
}


 renderContainerDetails = (container:any) => {
  const desiredRates = container.desired_rates ? `$${parseInt(container.desired_rates).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` : "";
  return (
      <Box style={{ display: 'flex' }}>
          <span>
              <span><b>{desiredRates}</b>/{container.container_size}</span>
          </span>
      </Box>
  );
}

 renderContainerDetailsList = (containerDetails:any) => {
  if (containerDetails.length > 1) {
   return  containerDetails[0]?.container_size === "20 FT" ?
       (
          <>
              {this.renderContainerDetails(containerDetails[1])}<Divider style={{margin:"4px"}}variant="middle" orientation="vertical" flexItem />{this.renderContainerDetails(containerDetails[0])}
          </>
      ) : (
          <>
              {this.renderContainerDetails(containerDetails[0])}<Divider style={{margin:"4px"}} variant="middle" orientation="vertical" flexItem />{this.renderContainerDetails(containerDetails[1])}
          </>
      )
  } else if (containerDetails.length === 1) {
      return this.renderContainerDetails(containerDetails[0]);
  } else {
      return null;
  }
}

 renderLiElement = (val:any) => {
  return (
      <li style={{ width: '220px', display: "flex", textAlign: 'center', whiteSpace: 'nowrap', marginLeft:'23px', alignItems: 'center', justifyContent: 'center' }}>
          {this.renderContainerDetailsList(val.container_details)}
      </li>
  );
}

renderColumnLabel = (elem: any, i: number) => {
  if(i == 0) {
    return(
      <li key={i} style={{width:"100px"}}>{elem}</li>
    )
  }
  if(i == 1) {
    return(
      <li key={i} style={{width:"100px"}}>{elem}</li>
    )
  }
  if(i == 2) {
    return(
      <li key={i} style={{width:"220px"}}>{elem}</li>
    )
  }
}


  RenderFirstView = () => {
    return (
      <Container maxWidth="xl" data-test-id="SellerListing">
        {this.FristGrid()}
        <StyledTabs
          value={this.state.currentTab}
          onChange={this.handleTabChange}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          aria-label="full width tabs example"
          variant="fullWidth"
          data-test-id="tab"
        >
          <NoBorderStyledTab disableTouchRipple label={`BUYER REQUESTS (${this.state.DynamicCount.requst})`} onClick={() => this.props.navigation.navigate("SellerPendingRequest")} />
          <StyledTab
            disableTouchRipple
            label={`LISTINGS (${this.state.DynamicCount.listing})`}
          />
          <BorderedStyledTab disableTouchRipple label={`OFFERS (${this.state.DynamicCount.offers})`} onClick={() => this.goToTermsAndConditionPage("SellerPendingOffers")} data-test-id="SellerPendingOffers" />
          <StyledTab disableTouchRipple label={`BOOKINGS (${this.state.DynamicCount.booking})`} onClick={() => this.props.navigation.navigate("SellerBookings")} />
        </StyledTabs>
        {this.FilterContainerRedner()}
        <TabPanel
          value={this.state.currentTab}
          index={1}
          data-test-id="SellerTable"
        >
         
            <TableContainer>
              <StyledTable aria-label="sticky table">
                <TableHead>
                  <StyledTableHeaderRow>
                    {columns.map((column: any) => (
                      <StyledTableCell
                        key={column.id}
                        style={{ minWidth: column.minWidth, textTransform: "uppercase" }}
                      >
                        <ul style={{ ...webStyle.dFlex, justifyContent: "space-around",whiteSpace:"nowrap",paddingLeft: '10px',width:"-moz-available" }}>
                          {column.label.map((elem: any, i: number) => (
                            this.renderColumnLabel(elem, i)
                          )
                          )}
                        </ul>
                      </StyledTableCell>
                    ))}
                  </StyledTableHeaderRow>
                </TableHead>
                <TableBody>
                  {/* Blue Color  */}

                  {Array.isArray(this.state.sellerListings) && this.state.sellerListings?.length !== 0&&
                    this.state.sellerListings?.map((list: any) => {
                    return (
                      <StyledTableRow
                        key={
                          list.seller_listing.data.attributes.listing_uid
                        }
                      >
                        <TableCell style={{...webStyle.tableLeftBorder,borderLeftColor: list.seller_listing.data.attributes.color_name ?? "rgb(184, 213, 249)",textAlign: "center"}}>
                        <span style={{backgroundColor: list.seller_listing.data.attributes.color_name ?? "rgb(184, 213, 249)", ...webStyle.org}}>
                            {list.seller_listing.data.attributes.org_code
                              ? list.seller_listing.data.attributes.org_code
                              : list.seller_listing.data.attributes
                                .organization_code}
                          </span>
                        </TableCell>
                        <TableCell style={{paddingLeft:"25px", ...webStyle.bodyTextAlignment}}>
                          <span style={webStyle.tableContent}>
                            {
                              list.seller_listing.data.attributes
                                .listing_uid
                            }
                          </span>
                        </TableCell>
                        <TableCell style={webStyle.bodyTextAlignment}>
                          <div style={webStyle.tableContent}>
                            <div>
                              {moment.parseZone(list.seller_listing.data.attributes.etb_pol).format("DD MMM YYYY")}
                            </div>
                            <div>
                              {moment.parseZone(list.seller_listing.data.attributes.etb_pol).format("h:mm A")}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell style={webStyle.bodyTextAlignment}>
                          <span style={webStyle.tableContent}>
                            {list.seller_listing?.data?.attributes?.vessel}
                            <br />{" "}
                            {list.seller_listing.data.attributes?.in_voyage}/{" "}
                            <br />{" "}
                            {list.seller_listing.data.attributes?.out_voyage}
                          </span>
                        </TableCell>
                        <TableCell style={webStyle.bodyTextAlignment}>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={webStyle.tableContent}>
                              {list.seller_listing.data.attributes.pol}/
                              <br />
                              {list?.seller_listing?.data?.attributes?.pol_terminal}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell style={webStyle.bodyTextAlignment}>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <span style={webStyle.tableContent}>
                              {list.seller_listing.data.attributes.pod}/
                              <br />
                              {list?.seller_listing?.data?.attributes?.pod_terminal}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell style={webStyle.bodyTextAlignment}>
                          <div style={{ ...webStyle.tableContent, display: 'flex', justifyContent: 'center' }}>
                            {moment.parseZone(
                              list.seller_listing.data.attributes.eta_pod
                            ).format("DD MMM YYYY")} <br />
                          </div>
                        </TableCell>
                        <TableCell style={{width:"200px"}}>
                          {list?.containers?.map((val: any) => {
                            return (
                              <ul 
                              style={{ ...webStyle.typeDiv,backgroundColor: list.seller_listing.data.attributes.color_name ?? "rgb(184, 213, 249)",
                                border: list.seller_listing.data.attributes.color_name ?? "rgb(184, 213, 249)",
                               padding: '4px',alignItems:'center', justifyContent: 'center' }}
                              key={val}>
                                <li style={{textAlign: 'center', width:'100px', marginLeft:'14px'}} >{val.container.container_type}</li>
                                <li style={{textAlign:'center', width:'100px', marginLeft:'10px'}}>
                                  {val?.container?.codes?.map(
                                    (code: string, index: number) => {
                                      return (
                                        <span key={code}>{code}{val.container.codes.length - 1 > index && `,`}</span>
                                      );
                                    })}
                                </li>
                                {this.renderLiElement(val)}
                              </ul>
                            );
                          })}
                        </TableCell>
                        
                        <TableCell style={webStyle.bodyTextAlignment}>
                          <div style={{paddingLeft:"10px", textAlign: 'center'}}>
                          {
  list.containers.map((data: { container_details: Array<{ terms: string }> }, index: number) => {
    let renderItemStr = ``
     data?.container_details?.map((item: any, _i: number) => {
      const renderedItem = this.getRenderedItem(data, index, _i);
      renderItemStr= `${renderItemStr} ${renderedItem} ${ data.container_details.length === 2 && _i===0 ? '|':''}` 
    })
    return(<ul style={{ display: "flex", alignItems: "center", justifyContent: "space-between", listStyle: "none", color: "#1E1E1E",fontWeight: 500,fontFamily: "Asap", padding: '4px',fontSize:'0.9375rem' }} key={`index${String.fromCharCode(94 + index)}`}>
    <li style={{textAlign: 'center', width:'100px',whiteSpace:'nowrap'}}><span>{renderItemStr}</span></li></ul>)
  })
}
                          </div>
                        </TableCell>
                        {this.TableBodyLastCell(list)}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </StyledTable>
            </TableContainer>
            {Array.isArray(this.state.sellerListings) &&
                    this.state.sellerListings?.length !== 0 ? (<>                
                   </> ) : (
                    <div className="noData">
                      <img
                        src={resultNotFound1}
                        alt="resultNotFound1"
                        className="noDataImg"
                      />
                      <p className="head">No Results Found!</p>
                      <p className="msg">Change or Remove Filters and Try Again.</p>
                    </div>
                  )}

        </TabPanel>
        <TabPanel value={this.state.currentTab} index={1}></TabPanel>
        <TabPanel value={this.state.currentTab} index={2}></TabPanel>
      </Container>
    )
  }

  Loader = () => {
    if (this.state.loading) {
      return <Spinner spinnerModal={this.state.loading} />
    }
  }

  render() {
    
    return (
      <StyleContainer>
        <Navbar SellerPendingScreen AppBarTransparent={false} TabDataCount={this.DynamicDataSet} />
        {this.Loader()}
        <section style={webStyle.root}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={this.state.sellerListingSnackbarOpen}
            autoHideDuration={5000}
            onClose={this.handleSnackbarClose}
          >
            <SnackbarContent
              style={{
                backgroundColor: '#fff',
                color: '#f00',
                fontWeight: 'bolder'
              }}
              message="Can not switch user as organisation role is not both"
            />
          </Snackbar>
          {this.RenderFirstView()}
          {this.DeleteModelRender()}
          {this.DeleteErrorModelRender()}
        </section>
      </StyleContainer>
    );
  }
}

export default withRouter(SellerListing);


const StyleContainer = styled(Box)({
  "& .DropDownDiv": {
    display: 'flex', 
    flexShrink: 0, 
    gap: '.625rem', 
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '.625rem', paddingRight: '.625rem',
    "& .DropDownDivh3": {
      margin:"0px",fontSize: '1rem',
      "@media (max-width: 600px)": 
      {
        fontSize: '10px',
      }, },
    "& .DropDownDivP": {fontSize: '1rem',
      "@media (max-width: 600px)": 
      {
        fontSize: '10px',
      }, },
    "@media (max-width: 600px)":
     {
      paddingLeft: '0px',
    },
  },
  "& .noData": {
    display: "flex", 
    fontFamily: "Asap",
    alignItems: "center", 
    justifyContent: "center",
    paddingTop: "96px", 
    flexDirection: "column",
  },
  "& .noDataImg": 
  {
    height: "80px",
  },"& .head": {
    margin: "8px",
    fontFamily: "Asap",
    fontSize: "32px",
    fontWeight: "bold",
  },
  "& .msg": {
    margin: "0px",
    fontFamily: "Asap",
    fontSize: "16px",
  },
  "& .EditAndDeletePopUp": {
    position: "absolute",
    left: '-4.5rem',
    "@media (max-width: 960px)": {
      left: '-4.5rem'
    },
  },
  "& .ThirDots": {
    cursor: "pointer"
  },
  "& .MenuContainer": {
    top: "-2.5rem !important"
  }
});

const LogoutModel = styled(Box)({
  "& .LogoutModelContainer": {
    paddingLeft: ".625rem",
    paddingRight: ".625rem",
    paddingBottom: "5rem",
    paddingTop: "1.875rem"
  },
  "& .QuetionMarkImgClass": {
    display: "flex",
    justifyContent: "center"
  }
})

const StyledChip = withStyles((theme) => ({
  root: {
    fontFamily: "Asap, sans-serif",
    background: "blue",
    color: "white",
    fontSize: ".875rem",
    flexDirection: "row-reverse",
    "& .MuiChip-deleteIcon": {
      color: "rgba(255, 255, 255, 1)",
    },
    "& .MuiChip-label": {
      paddingRight: "0rem"
    },
    "& .MuiChip-icon": {
      color: "rgba(255, 255, 255, 1)",
      margin: "0rem"
    }
  },
}))(Chip);

const StyledBox = withStyles((theme) => ({
  root: {
    position: "relative",
    padding: "1.25rem 6.875rem",
    backgroundColor: theme.palette.common.white,
    border: ".0625rem solid #ced4da",
    fontSize: 16,
    borderRadius: 8,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    cursor: "pointer",
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderColor: "#000",
    },
  },
}))(Box);

export const CustomBox = styled(Box)(({ theme }) => ({
  background: "#FFF",margin: "0rem",
  display: "block",padding: "1.5625rem 1.25rem",
  justifyContent: "space-between",
  [theme.breakpoints.up("lg")]: {
    display: "flex",
  },
}));
const DropdownArrowBox = styled(Box)({
  root: {
    position: "relative"
  },
  "& img": {
    height: "10px", width: "10px", 
    position: "absolute",top: "15px", 
    zIndex: 1, right: "20px",
    cursor: "pointer"

  }
});

export const webStyle = {
  root: {
    background: "#eee",
    padding: "1.875rem 0",
    minHeight: "100vh",
  },
  slotsSection: {
    marginBottom: "3.125rem",
    justifyContent: 'center'
  },
  bodyTextAlignment: {
    textAlign: "center" as "center",
  },
  boxtextp:{
    marginLeft:"4px", width: "fit-content",
    height: "fit-content",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 900,
    fontFamily: "Asap",
    padding: "4px 16px 4px",
    background: "rgb(184, 213, 249)",
    borderRadius:"4px", whiteSpace: "nowrap" as "nowrap",
  },
  leftBar: {
    display: "flex",
    alignItems: "center",
  },
  noData:{
    display: "flex",
   fontFamily: "Asap",
   justifyContent: "center",
    alignItems: "center",
    paddingTop: "96px",
  },
   noDataImg: {
    height: "80px",
  },
   head : {
    margin: "8px",
    fontFamily: "Asap",
    fontSize: "32px",
  },
   msg: {
    margin: "0px",
    fontFamily: "Asap",
    fontSize: "16px",
  },
  slotsFor: {
    margin: 0,
    fontFamily: `"Asap", "sans-serif"`,
    fontWeight: 800,
    fontSize: "24px",
    color: "#1E1E1E",
  },
  selectSlot: {
    width: "24.3125rem",
    height: "3rem",
    background: "rgba(255, 255, 255, 1)",
    border: ".0625rem solid rgba(205, 205, 205, 1)",
    marginLeft: ".9375rem",
    padding: '1rem .75rem',
    borderRadius: ".5rem",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer' 
  },
  selectSlotDropdown: {
    width: '24.125rem',
    position: 'absolute' as 'absolute',
    background: 'white',
    borderRadius: '.5rem',  
    boxShadow: "rgba(100, 100, 111, 0.2) 0rem .4375rem 1.8125rem 0rem",
    padding: '.25rem .375rem',
    display: 'flex',
    left: '.9375rem',
    marginTop: '.25rem',
    flexDirection: "column" as "column",
    gap: '.125rem',
    zIndex: 2,
  },
  selectSlotItem: {
    display: 'flex', width: '100%',
    height: '2.25rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '1rem'
  },
  btnDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center" as "center"
  },
  monthlyPricing: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: ".875rem",
    background: "transparent",
    border: ".0625rem solid #1E1E1E",
    borderRadius: "2rem",
    color: "#1E1E1E",
    marginRight: "1.875rem",
    fontWeight: 600,
    height: "3rem",
    width: "16rem",
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer",
    fontFamily: "Asap"
  },
  btnImgDiv: {
    marginRight: ".5rem",
  },
  textEnd: {
    display: "flex",justifyContent: "end",
  },
  org: {
    color: "#1E1E1E",
    fontSize: ".9375rem",
    fontWeight: 500,
    fontFamily: "Asap",
    padding: ".375rem .75rem",
    borderRadius: ".25rem",
  },
  tableContent: {
    color: "#363636", 
    fontSize: ".9375rem",
    fontWeight: 500,
    fontFamily: "Asap",
  },
  ETBCell: {
    display: 'flex',
    flexDirection: 'column'
  },
  typeDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    listStyle: "none", 
    background: "#B8D5F9",
    color: "#1E1E1E",
    fontSize: ".9375rem",
    fontWeight: 500,
    fontFamily: "Asap", 
    padding: ".375rem .75rem",
    border: ".0625rem solid #6495D2",
    borderRadius: ".25rem",
    marginBottom: ".1875rem",
  },

  quantityDiv: {
    display: "flex",
    alignItems: "center",
    border: ".0625rem solid #DADADA",
    borderRadius: ".5rem",
    padding: ".625rem 1.25rem",
    marginRight: ".9375rem",
    width: "12.25rem"
  },
  available: {
    color: "#3C7C09",
    fontSize: ".9375rem",
    fontWeight: 500,
    fontFamily: "Asap",
  },
  availableQuantity: {
    color: "#3C7C09",
    fontSize: "1.125rem",
    fontWeight: 500,
    fontFamily: "Asap",
    margin: 0,
  },
  total: {
    color: "#1E1E1E",
    fontSize: ".9375rem",
    fontWeight: 500,
    fontFamily: "Asap",
    background: "#EAEAEA",
    padding: ".3125rem .625rem",
    borderRadius: ".25rem",
    whiteSpace: 'nowrap' as 'nowrap'
  },
  bookedAmount: {
    color: "#DB2525",
    fontSize: ".9375rem",
    fontWeight: 500,
    fontFamily: "Asap",
    textAlign: "center" as "center",
    boxShadow: "rgba(149, 157, 165, 0.2) 0rem .5rem 1.5rem",
    marginTop: ".625rem",
  },
  mr15: {
    marginRight: ".9375rem",
  },
  tableLeftBorder: {
    borderLeft: "3px solid rgb(184, 213, 249)",
    borderRadius: ".375rem",
    // textAlign: "center" as "center",
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    ustifyContent: "space-between",
  },
  textCenter: {
    textAlign: "center" as "center",
  },
  tableYellowBorder: {
    borderLeft: ".1875rem solid #D0B655",
    borderRadius: ".375rem",
    textAlign: "center" as "center"
  },
 
  ellipsis: {
    cursor: "pointer"
  },
  addListingModal: {
    padding: "1.25rem .625rem"
  },
  close: {
    textAlign: "end" as "end",
    cursor: "pointer",
  },
  title: {
    fontFamily: "Asap, sans-serif",
    fontSize: "1.75rem",
    color: "#1E1E1E",
    margin: "0 0 .9375rem 0",
    textAlign: "center" as "center"
  },
  organisation: {
    margin: "0 1.25rem 1.25rem 1.25rem",
  },
  principalLabel: {
    color: "#363636",
    fontSize: ".875rem",
    fontFamily: "Asap, sans-serif",
    fontWeight: 600,
  },
  vesselContainer: {
    borderRadius: "1.125rem",
    background: "#E3E6ED",
    padding: "1.5625rem .625rem",
    marginBottom: ".9375rem",
  },
  vesselHeading: {
    color: "#1E1E1E",
    fontSize: "1.125rem",
    fontFamily: "asap, sans-serif",
    textAlign: "center" as "center",
    margin: "0 0 1.5625rem 0",
  },
  inputText: {
    border: ".0625rem solid #CBD5E1",
    borderRadius: ".3125rem",
    height: "2.75rem",
    width: "100%",
    padding: "0 .5rem"
  },
  validityBox: {
    display: "flex",
    alignItems: "center",
    borderRadius: ".5rem",
    border: ".0625rem solid #CBD5E1",
  },
  validtyInputText: {
    border: "none",
    height: "2.75rem",
    width: "2.5rem",
    borderBottomLeftRadius: ".3125rem",
    padding: "0 .5rem", 
    borderTopLeftRadius: ".3125rem",
  },
  etbDiv: {
    marginLeft: ".625rem",
  },
  beforeETB: {
    color: "#797979", 
    fontSize: ".875rem",
    fontFamily: "Asap, sans-serif",
  },
  expiryDate: {
    color: "#1E1E1E",
    fontSize: ".875rem",
    fontFamily: "Asap, sans-serif",
  },
  expiryDateDiv: {
    borderRadius: ".5rem",
    height: "2.5rem",
    display: "flex", 
    alignItems: "center",
    border: ".0625rem solid #CBD5E1",
     paddingLeft: ".625rem",
  },
  bBottom: {
    border: ".0625rem solid #CBD5E1", 
    margin: "0 .125rem 1.25rem .125rem",
  },
  rateBox: {
    display: "flex",
     background: "#fff",
    alignItems: "center", 
    border: ".0625rem solid #CBD5E1",
    borderRadius: ".5rem",
  },
  borderRight: {
    borderRight: ".0625rem solid #CDCDCD",
    height: "2.5625rem",
  },
  usd: {
    margin: "0 .625rem",
  },
  rateDiv: {
    color: "#1E1E1E",
    fontSize: ".875rem",
    fontFamily: "Asap, sans-serif",
  },
  inputRateText: {
    border: "none", 
    borderBottomLeftRadius: ".3125rem",
    height: "2.75rem",
    width: "2.5rem",
    borderTopLeftRadius: ".3125rem",
    padding: "0 .5rem",
  },
  slash: {
    color: "gray",position: "relative" as "relative",
    left: ".75rem",
  },
  ml5: {
    marginLeft: ".3125rem",
  },
  dFlexJCenter: {
    display: "flex",alignItems: "center",
    justifyContent: "center",
  },
  btnTheme: {
    background: "#1653DD",
    border: "none",
    display: "flex", 
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    padding: ".5625rem 1.125rem",
    color: "#FFFFFF",
    fontWeight: 600,
    height: "2.8125rem",
    borderRadius: "2rem",
    width: "16rem",
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer",
  },
  listingSubmittedBox: {
    padding: ".625rem 1.25rem",
  },
  mb20: {
    marginBottom: "1.25rem",
  },
  listingHeading: {
    color: "#1E1E1E",
    fontWeight: 700,
    fontSize: "1.75rem", 
    fontFamily: "Asap, sans-serif",
  },
  listingIdPara: {
    color: "#363636", 
    fontSize: "1rem",
    fontFamily: "Asap, sans-serif",
    margin: "0 0 .3125rem 0",
  },
  pTB50: {
    padding: "3.125rem 0",
  },
  // Listing submitted Css

  // Matching Requests Available for Your Listing! btn css
  viewOfferBtn: {
    fontSize: "1rem",
    color: "#FFFFFF",
    background: "#1653DD",
    border: "none",
    borderRadius: "2rem",
    padding: ".5625rem 1.125rem",
    fontWeight: 600,
    height: "2.8125rem",
    width: "13.25rem",
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer",
    marginTop: "1.5625rem",
  },
  // Matching Requests Available for Your Listing! btn css

  // Delete modal css
  deleteBtn: {
    fontSize: "1rem",
    padding: ".5625rem 1.125rem",
    background: "#DB2525",
    border: "none",
    borderRadius: "2rem",
    color: "#FFFFFF",
    fontWeight: 600,
    height: "2.8125rem",
    width: "13.25rem",
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer",
    marginRight: ".9375rem",
  },
  cancelBtn: {
    borderRadius: "2rem",
    fontSize: "1rem",
    background: "#FFFFFF",
    border: ".0625rem solid #000000",
    padding: ".5625rem 1.125rem",
    color: "#000000",
    fontWeight: 600,
    height: "2.8125rem",
    width: "13.25rem",
    textTransform: "uppercase" as "uppercase",
    cursor: "pointer",
  },
  // Delete modal css

  // User Guide videos modal css
  videoContainer: {
    background: "#E3E6ED",
    paddingBottom: ".9375rem",
    borderRadius: "1.375rem",
  },
  videoText: {
    color: "#1E1E1E",
    margin: ".9375rem .75rem .3125rem .75rem",
    fontSize: ".9375rem",
    fontFamily: "Asap, sans-serif",
    fontWeight: 600,
  },
  timeStamp: {
    color: "#797979",
    fontSize: ".9375rem",
    fontFamily: "Asap, sans-serif",
    margin: "0 .75rem",
  },
  // User Guide videos modal css

  editText: {
    cursor: "pointer",
    fontFamily: "Asap",
    fontSize: ".875rem",
    color: "#1E1E1E",
    margin: "0 0 .9375rem 0",
  },
  deleteText: {
    cursor: "pointer",
    fontFamily: "Asap",
    fontSize: ".875rem",
    color: "#DC2626",
    margin: 0
  },
  uncontrolledBox: {
    background: "#fff",
    display: "inline-block",
    padding: ".9375rem",
    minWidth: "6.25rem",
    textAlign: "end" as "end",
    boxShadow: "rgba(100, 100, 111, 0.2) 0rem .4375rem 1.8125rem 0rem",
    borderRadius: ".5rem"
  },
  verticalLine: { 
    position: "absolute" as "absolute", 
    height: "2.0625rem", 
    width: ".0625rem", 
    background: "#7DB7FF", 
    top: "-0.375rem", 
    right: "4.5625rem" 
  }

};

const DatePickerStyle = styled(DateRangePicker)({
  "& .MuiInputLabel-animated": {
    transition: "unset"
  },
  "& .DateMainClassName": {
    "& .MuiPaper-root": {
      paddingBottom: "60px !important",
      backgroundColor: "grey",
    }
  }
})

const DateContainer = styled(Box)({
  "& .ButtonContaienr": {
    position: "absolute",
    right: "0px",
    display: "flex",
    justifyContent: "flex-end",
    bottom: "0px",
    zIndex: 9,
    padding: ".625rem .375rem"
  },
  "& .DateMainClassName": {
    "& .MuiInputBase-input":{
      fontFamily: "Asap, sans-serif",
    },
    "& .MuiPaper-root": {
      "& .materialui-daterange-picker-MuiInput-underline-37": {
        visibility: "hidden"
      },
      paddingBottom: "20px",
      "& .MuiGrid-wrap-xs-nowrap": {
        "&.MuiGrid-root": {
          "& .materialui-daterange-picker-MuiList-root-59": {
            display: "none"
          },
        }
      }
    },
    "& .MuiTypography-root": {
      fontWeight: "500 !important",
      fontFamily: 'Asap !important'
    },
    "& .materialui-daterange-picker-makeStyles-highlighted-57": {
      color: "red !important",
    }
  },
  "& ul": {
    marginBottom: "20px"
  }
})

const CalendarImgBox = styled(Box)({
  root: {
    position: "relative"
  },
  "& img": {
    cursor: "pointer",
    height: "24px", width: "24px",
    position: "absolute",
    top: "32px", zIndex: 1,
    right: "12px"
  }
})
const CustomFormControlLabel = styled(FormControlLabel)({
  "& span": {
    fontFamily: "Asap"
  }
})
// Customizable Area End