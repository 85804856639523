// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

interface S {
  // Customizable Area Start
  enableOLdPasswordField: boolean;
  enableNewPasswordField: boolean;
  enableConfirmField: boolean;
  newAuth: string;
  oldAuth: string;
  confirmPassword: string;
  APIResponseError: boolean;
  isEditFnameModalOpen: boolean;
  isEditPasswordModalOpen: boolean;
  email: string;
  FullName: string;
  passwordChangeDate: string;
  designation: string;
  department: string;
  contactNumber: string;
  organization: string;
  isEditFullName: string;
  isEditdesignation: string;
  isEditdepartment: string;
  isEditcontactNumber: string;
  notificationSettingToggle: boolean;
  requestEmailStatus: boolean;
  offerEmailStatus: boolean;
  sellerEmailStatus: boolean;
  bookingEmailStatus: boolean;
  distributionEmailStatus: boolean;
  distributionAppStatus: boolean;
  requestAppStatus: boolean;
  offerAppStatus: boolean;
  sellerAppStatus: boolean;
  bookingAppStatus:boolean;
  isBuyerRole: boolean;
  phoneNumber: string;
  mainPhoneNumber: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class BuyerUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserUpdateProfileDataApiCallId: string = "";
  labelTitle: string = "";
  getUserProfileDataApiCallId: string = "";
  getUserPasswordApiCallId: string = "";
  getEmailSettingApiCallId: string = "";
  getAppSettingApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    (async () => {
      let profileLoginData = await getStorageData("loginData");
      if(!profileLoginData){
        window.location.href = "/"
      }
    })()
    this.state = {
       newAuth: "",
       oldAuth: "",
       confirmPassword: "",
       email: "",
       FullName: "",
       passwordChangeDate:"",
       designation: "",
       department: "",
       contactNumber: "",
       APIResponseError: false,
       enableOLdPasswordField: true,
       enableNewPasswordField: true,
       enableConfirmField: true,
       organization: "",
       isEditFullName: "",
       isEditdesignation: "",
       isEditdepartment: "",
       isEditFnameModalOpen: false,
       isEditPasswordModalOpen: false,
       isEditcontactNumber: "",
       notificationSettingToggle: false,
       requestEmailStatus: false,
       offerEmailStatus: false,
       sellerEmailStatus: false,
       bookingEmailStatus:false,
       requestAppStatus: false,
       offerAppStatus: false,
       sellerAppStatus: false,
       bookingAppStatus:false,
       isBuyerRole: false,
      distributionEmailStatus:false,
      distributionAppStatus:false,
      phoneNumber: "",
      mainPhoneNumber: "",
    };
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.labelTitle = configJSON.labelTitle;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start


  async componentDidMount() {
    this.getUserProfile();
    const UserRole = await getStorageData("UserRole") === "buyer"
    this.setState({
      isBuyerRole: UserRole,
    })
  }

  handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const phoneNumber = event.currentTarget.value;
    this.setState({
      contactNumber: phoneNumber,
      mainPhoneNumber: phoneNumber
    });
  };

  handleChange = (number: string, numberDetails: string) => {
    const phoneNumber = number.slice(numberDetails.length, number.length);
    const countryCode = number.slice(0, numberDetails.length)
    this.setState({
      contactNumber: `+${countryCode} ${phoneNumber}`,
      mainPhoneNumber: phoneNumber,
    });    
  };
  handleClickShowOldPassword = () => {
    this.setState({
      enableOLdPasswordField: !this.state.enableOLdPasswordField
    });
  };
  handleClickShowNewPassword = () => {
    this.setState({
      enableNewPasswordField: !this.state.enableNewPasswordField
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmField: !this.state.enableConfirmField
    });
  };
  setNewPassword = (text: string) => {
    this.setState({
      newAuth: text
    });
  };
  setOldPassword = (text: string) => {
    this.setState({
      oldAuth: text
    });
  };
  setConfirmPassword = (text: string) => {
    this.setState({
      confirmPassword: text
    });
  };
  setFullName = (text: string) => {
    this.setState({
      FullName: text,
      isEditFullName: text
    });
  };
  setdesignation = (text: string) => {
    this.setState({
      designation: text,
      isEditdesignation: text
    });
  };
  setdepartment = (text: string) => {
    this.setState({
      department: text,
      isEditdepartment: text
    });
  };

  toggleModal = () => {
    this.setState(prevState => ({
      isEditFnameModalOpen: !prevState.isEditFnameModalOpen
    }));
    this.getUserProfile()
  };

  editPasswordModal = () => {
    this.setState(prevState => ({
      isEditPasswordModalOpen: !prevState.isEditPasswordModalOpen,
      newAuth:"",
      oldAuth:"",
      confirmPassword:"",
      enableOLdPasswordField:true,
      enableConfirmField:true,
      enableNewPasswordField:true
    }));
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getUserProfileDataApiCallId) {
      this.setState({
        email: responseJson.data.attributes.email,
        FullName: responseJson.data.attributes.full_name,
        passwordChangeDate: responseJson.data.attributes.password_last_changed ? moment.parseZone(responseJson.data.attributes.password_last_changed).format("DD MMM YYYY") :  moment.parseZone(responseJson.data.attributes?.created_at).format("DD MMM YYYY"),
        designation: responseJson.data.attributes.designation,
        department: responseJson.data.attributes.department,
        contactNumber: responseJson.data.attributes.full_phone_number,
        mainPhoneNumber: responseJson.data.attributes.full_phone_number,
        organization: responseJson.data.attributes.organization_name,
        requestEmailStatus: responseJson.data.attributes.request_email_status,
        offerEmailStatus: responseJson.data.attributes.offer_email_status,
        sellerEmailStatus: responseJson.data.attributes.seller_email_status,
        bookingEmailStatus: responseJson.data.attributes.booking_email_status,
        requestAppStatus: responseJson.data.attributes.request_app_status,
        bookingAppStatus: responseJson.data.attributes.booking_app_status,
        offerAppStatus: responseJson.data.attributes.offer_app_status,
        sellerAppStatus: responseJson.data.attributes.listing_app_status,
        distributionEmailStatus:responseJson.data.attributes.distribution_email_status,
        distributionAppStatus:responseJson.data.attributes.distribution_app_status,

      });

    }
    if (apiRequestCallId === this.getUserUpdateProfileDataApiCallId) {
      this.userUpdateProfileDataSuccessCallBack()
    }
    if (apiRequestCallId === this.getUserPasswordApiCallId) {
      if (responseJson.error) {
        toast.error(responseJson.error[0]);
      } else {
        toast.success(responseJson.message);
        this.editPasswordModal()
        this.getUserProfile();
      }
    }
    if (apiRequestCallId === this.getEmailSettingApiCallId) {
      this.getUserProfile();
    }
    if (apiRequestCallId === this.getAppSettingApiCallId) {
      this.getUserProfile();
    }
  }


  getUserProfile = async () => {
    const tokens: any = await getStorageData("loginData");
    const tokenData = JSON.parse(tokens);

    const header = {
      "Content-Type": "application/json",
      'token': tokenData?.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/specific_account"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  UserUpdateProfile = async () => {
    const tokens: any = await getStorageData("loginData");
    const tokenData = JSON.parse(tokens);

    const header = {
      'token': tokenData?.token,
      contentType: "multipart/form-data",
    };

    const formData = new FormData();
    this.state.FullName &&
      formData.append("account[full_name]", this.state.FullName);
    this.state.designation &&
      formData.append("account[designation]", this.state.designation);
    this.state.department &&
      formData.append("account[department]", this.state.department);
    this.state.contactNumber &&
      formData.append("account[full_phone_number]", this.state.contactNumber.replace(/[()]/g, '').replace(/\s{2,}/g, ' ').trim());

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserUpdateProfileDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/update_account"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  updateUserPassword = async () => {
    const tokens: any = await getStorageData("loginData");
    const tokenData = JSON.parse(tokens);
    const header = {
      "Content-Type": "application/json",
      token: tokenData?.token,
    };

    const httpBody = {
      current_password: this.state.oldAuth,
      new_password: this.state.newAuth
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserPasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/change_password"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  updateEmailStatus = async (key: string, value: boolean) => {
    const tokens: any = await getStorageData("loginData");
    const tokenData = JSON.parse(tokens);
    const header = {
      "Content-Type": "application/json",
      token: tokenData?.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEmailSettingApiCallId = requestMessage.messageId;
    let httpBody = {
      account: {
        [key]: value
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/email_status"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateAppStatus = async (key: string, value: boolean) => {
    const tokens: any = await getStorageData("loginData");
    const tokenData = JSON.parse(tokens);
    const header = {
      "Content-Type": "application/json",
      token: tokenData?.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAppSettingApiCallId = requestMessage.messageId;
    let httpBody = {
      account: {
        [key]: value
      }
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/app_notification_status"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  userUpdateProfileDataSuccessCallBack = () => {
    this.toggleModal();
    this.getUserProfile();
  };
  // Customizable Area End
}
